import React from 'react';

import {
	AspectRatio,
	Box,
	Card,
	CardBody,
	CardFooter,
	CardProps,
	Center,
	Divider,
	Image,
	Tag,
	Text,
	Wrap,
} from '@chakra-ui/react';

type SongCardProps = CardProps & {
	id: string;
	name: string;
	artworkUrl?: string | null;
	artist: string;
	footer?: React.ReactNode;
	songThemes?: {
		emotionTags: string[];
		energyTags: string[];
		gospelTags: string[];
		songTypeTags: string[];
	};
	overlay?: React.ReactNode;
	overlayColor?: string;
	variations?: string;
};

const SongCard: React.FC<SongCardProps> = ({
	id,
	name,
	artworkUrl,
	artist,
	footer,
	songThemes,
	overlay,
	overlayColor,
	variations,
	...props
}) => {
	return (
		<Card {...props} display="inline-flex">
			<CardBody position="relative">
				<Image
					width="240px"
					height="240px"
					src={artworkUrl ?? ''}
					fallbackSrc="https://via.placeholder.com/240"
				/>
				{overlay ? (
					<AspectRatio
						marginTop="-100%"
						opacity={0.5}
						w="100%"
						background={overlayColor ? overlayColor : 'blue.200'}
						ratio={1}
					>
						<Center>
							<Text
								fontSize="70px"
								color="white"
								fontWeight="bold"
							>
								{overlay}
							</Text>
						</Center>
					</AspectRatio>
				) : null}
				<Text mt="10px" as="b">
					{name}
				</Text>
				<Text>{artist}</Text>
				{songThemes && (
					<Wrap mt={2}>
						{[
							...songThemes.energyTags,
							...songThemes.emotionTags,
							...songThemes.gospelTags,
							...songThemes.songTypeTags,
						].map((t) => (
							<Tag size="sm">{t}</Tag>
						))}
					</Wrap>
				)}
				<Text>variations: {variations}</Text>
			</CardBody>
			{footer && (
				<>
					<Divider borderColor="brand.200" />
					<CardFooter>{footer}</CardFooter>
				</>
			)}
		</Card>
	);
};

export default SongCard;
