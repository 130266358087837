const sizes = {
	// max: 'max-content',
	// min: 'min-content',
	// full: '100%',
	// '3xs': '14rem',
	// '2xs': '16rem',
	// xs: '20rem',
	// sm: '24rem',
	// md: '28rem',
	// lg: '32rem',
	// xl: '36rem',
	// '2xl': '42rem',
	// '3xl': '48rem',
	// '4xl': '56rem',
	// '5xl': '64rem',
	// '6xl': '72rem',
	// '7xl': '80rem',
	// '8xl': '90rem',
	container: {
		base: '100%',
		sm: '100%',
		md: '720px',
		lg: '940px',
		xl: '1180px',
		'2xl': '1510px',
	},
};

export default sizes;
