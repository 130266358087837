import React, { PropsWithChildren, useState } from 'react';

import VideoCard from '@/components/shared/VideoCard';
import { useDeleteVideoMutation, useListVideosQuery } from '@/graphql';
import { VideoInputSchema } from '@/graphql/validation';
import { useAuth } from '@/providers/AuthProvider';
import {
	Button,
	ButtonGroup,
	Flex,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';

import CreateVideoModal, {
	CreateVideoInitialValues,
} from './components/CreateVideoModal';

const Videos = () => {
	const { data, error, isLoading, refetch } = useListVideosQuery();
	const { mutateAsync } = useDeleteVideoMutation({
		onSuccess: () => {
			refetch();
		},
	});
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const [initialData, setInitialData] = useState<CreateVideoInitialValues>();

	if (isLoading) {
		return <Spinner />;
	}

	if (error || !data) {
		throw error;
	}

	return (
		<>
			<CreateVideoModal
				isOpen={isOpen}
				onClose={onClose}
				onSuccess={refetch}
				initialData={initialData}
			/>
			<Flex gap="20px" mt="30px" alignItems="center" flexWrap="wrap">
				{data.listVideos.map((video) => {
					if (!video) {
						return null;
					}
					return (
						<VideoCard
							flexShrink={0}
							key={video.id}
							w="300px"
							{...video}
							footer={
								<ButtonGroup spacing="2" w="100%">
									<Button
										variant="outline"
										onClick={() => {
											setInitialData({
												...video,
												duration:
													video.duration.toString(),
											});
											onOpen();
										}}
									>
										Edit
									</Button>
									<Popover>
										{({ onClose }) => (
											<>
												<PopoverTrigger>
													<Button
														variant="ghost"
														colorScheme="red"
													>
														Delete
													</Button>
												</PopoverTrigger>
												<PopoverContent>
													<PopoverArrow />
													<PopoverHeader>
														Confirmation!
													</PopoverHeader>
													<PopoverBody>
														<ButtonGroup
															spacing="2"
															w="100%"
														>
															<Button
																variant="solid"
																colorScheme="red"
																onClick={async () => {
																	try {
																		await mutateAsync(
																			{
																				videoId:
																					video.id,
																			},
																		);

																		toast({
																			title: `Video ${video.name} deleted!`,
																		});
																		onClose();
																	} catch (err) {
																		console.error(
																			err,
																		);
																		toast({
																			title: 'Error deleting video!',
																			status: 'error',
																			description:
																				err instanceof
																				Error
																					? err.message
																					: 'Unkown error, check console',
																		});
																	}
																}}
															>
																Yes
															</Button>
															<Button
																variant="ghost"
																onClick={
																	onClose
																}
															>
																No
															</Button>
														</ButtonGroup>
													</PopoverBody>
												</PopoverContent>
											</>
										)}
									</Popover>
								</ButtonGroup>
							}
						/>
					);
				})}
			</Flex>

			<Button
				display="block"
				mx="auto"
				mt="150px"
				flexShrink={0}
				onClick={() => {
					setInitialData(undefined);
					onOpen();
				}}
			>
				Create video
			</Button>
		</>
	);
};

export default Videos;
