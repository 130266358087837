import React from 'react';

import { useAuth } from '@/providers/AuthProvider';
import { useEnvironment } from '@/providers/EnvironmentProvider';
import { Button, Heading, Input, Link, Text, VStack } from '@chakra-ui/react';
import axios from 'axios';
import { Formik } from 'formik';
import ReactCodeInput from 'react-code-input';
import * as Yup from 'yup';

type LoginResponse = {
	jwt: string;
	userId: string;
	teamId: string;
	user: {
		email: string;
		id: string;
		displayName: string;
	};
};

const signUpValidationSchema = Yup.object().shape({
	code: Yup.string().required('Please enter your code'),
});

const VerifyForm: React.FC<{
	email: string;
	onChange: (email: string | null) => void;
}> = ({ email, onChange }) => {
	const {
		api: { url },
	} = useEnvironment();
	const { authenticate } = useAuth();

	return (
		<Formik
			key="asaphSignUn"
			initialValues={{
				code: '',
			}}
			validationSchema={signUpValidationSchema}
			onSubmit={async (values, formikHelpers) => {
				if (values.code.length < 4) {
					formikHelpers.setSubmitting(false);
					return formikHelpers.setFieldError(
						'code',
						'Code is invalid',
					);
				}
				try {
					const res = await axios.post<LoginResponse>(
						`${url}/auth/login`,
						{
							email,
							code: values.code,
						},
					);

					if (!res.data.jwt) {
						throw new Error('Response did not produce a valid jwt');
					}

					authenticate(res.data.jwt);
				} catch (err) {
					console.error(err);
					formikHelpers.setErrors({
						code: 'Invalid code',
					});
				}
				formikHelpers.setSubmitting(false);
			}}
		>
			{({
				handleSubmit,
				values,
				isSubmitting,
				setFieldValue,
				touched,
				errors,
			}) => (
				<form onSubmit={handleSubmit} id="asaphSignIn">
					<VStack>
						<Heading>Verification</Heading>
						<Text textAlign="center">
							Please check your email for a verification code.
							Logging in as {email} -{' '}
							<Link onClick={() => onChange(null)}>
								click here
							</Link>{' '}
							to change.
						</Text>
						<div className="text-center">
							<ReactCodeInput
								name="code"
								inputMode="numeric"
								onChange={(value) => {
									setFieldValue('code', value);
									if (value.length > 3) {
										handleSubmit();
									}
								}}
								value={values.code}
								fields={4}
								inputStyle={{
									textAlign: 'center',
									MozAppearance: 'textfield',
									borderRadius: '6px',
									border: '1px solid var(--color-2)',
									boxShadow:
										'0px 0px 10px 0px rgba(0,0,0,.10)',
									margin: '4px',
									width: '36px',
									height: '42px',
									boxSizing: 'border-box',
									fontSize: '20px',
								}}
							/>
						</div>
						<div className="vsb-8" />
						<Button type="submit" disabled={isSubmitting}>
							Verify
						</Button>
						<div className="vsb-20">
							{!!errors.code && touched.code && (
								<Text colorScheme="warning">{errors.code}</Text>
							)}
						</div>
					</VStack>
				</form>
			)}
		</Formik>
	);
};

export default VerifyForm;
