import React, { createContext, PropsWithChildren, useState } from 'react';

import { useListPipelineJobsQuery } from '@/graphql';
import { useAuth } from '@/providers/AuthProvider';
import { Spinner, useDisclosure, Wrap, WrapItem, Text, Center } from '@chakra-ui/react';

import { AdminJobTile } from './components/AdminJobTile';
import Filters from './components/Filters';
import VideoPlayerModal from './components/VideoPlayerModal';

interface PipelineJobContext {
	videoUrl?: string;
}

const defaultContext: PipelineJobContext = {
	videoUrl: '',
};

export const PipelineJobContext =
	createContext<PipelineJobContext>(defaultContext);

const PipelineJobs = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [sortBy, setSortBy] = useState('');
	const [sortDirection, setSortDirection] = useState('');
  const [enabled, setEnabled] = useState(false);
	const { data, error, isLoading, fetchStatus } = useListPipelineJobsQuery(
		{
			filter: {
				srcVideo: searchTerm,
			},
		},
		{
			refetchOnWindowFocus: false,
      enabled,
		},
	);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [videoUrl, setVideoUrl] = useState<string>();

	if (error) {
		return <div>There was an error</div>;
	}

  const results = data?.listPipelineJobs || [];

	return (
		<PipelineJobContext.Provider
			value={{
				videoUrl,
			}}
		>
			<VideoPlayerModal isOpen={isOpen} onClose={onClose} />
			<Filters mb={10} values={{ srcVideo: searchTerm, sortBy, sortDirection }} onChange={({ srcVideo }) => {
        setSearchTerm(srcVideo);
        setEnabled(true);
      }} />
      { isLoading && fetchStatus !== 'idle' ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        results.length ? (
          <Wrap mt="30px">
            {results.map((job) => {
              if (!job) {
                return null;
              }
              return (
                <AdminJobTile
                  onClick={(videoUrl) => {
                    setVideoUrl(videoUrl);
                    onOpen();
                  }}
                  job={job}
                  key={`job-${job.guid}`}
                />
              );
            })}
          </Wrap>
        ) : <Text align="center" size="lg">No results</Text>
      )}
		</PipelineJobContext.Provider>
	);
};

export default PipelineJobs;
