import React, { PropsWithChildren, useCallback, useState } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import VideoCard from '@/components/shared/VideoCard';
import {
	ListVideoCollectionsQuery,
	UpdateVideoCollectionMutationVariables,
	useDeleteVideoCollectionMutation,
	useListVideoCollectionsQuery,
	useUpdateVideoCollectionMutation,
	VideoCollectionInput,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { VideoInputSchema } from '@/graphql/validation';
import { useAuth } from '@/providers/AuthProvider';
import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { arrayMoveImmutable } from 'array-move';
import { pick } from 'ramda';

import CreateVideoCollectionModal, {
	CreateVideoCollectionInitialData,
} from './components/CreateVideoCollectionModal';
import SelectVideoModal from './components/SelectVideoModal';

type VideoCollection =
	ListVideoCollectionsQuery['listVideoCollections'][number];

const buildInput = (
	videoCollection: VideoCollection,
	videoIds?: string[],
): UpdateVideoCollectionMutationVariables => {
	return {
		videoCollectionId: videoCollection.id,
		input: {
			name: videoCollection.name,
			onboarding: videoCollection.onboarding,
      paid: videoCollection.paid,
			videoIds: videoIds ?? videoCollection.videoIds,
      hex: videoCollection.hex,
		},
	};
};

const VideoCollections = () => {
	const { data, error, isLoading, refetch } = useListVideoCollectionsQuery<
		ListVideoCollectionsQuery,
		FetchError
	>();
	const { mutateAsync } = useUpdateVideoCollectionMutation<FetchError>({
		onSuccess: () => refetch(),
	});
	const { mutateAsync: deleteVideoCollection } =
		useDeleteVideoCollectionMutation<FetchError>({
			onSuccess: () => refetch(),
		});
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isSelectVideoOpen,
		onOpen: onSelectVideoOpen,
		onClose: onSelectVideoClose,
	} = useDisclosure();
	const [videoCollectionContext, setVideoCollectionContext] =
		useState<VideoCollection>();
	const toast = useToast();
	const [initialData, setInitialData] =
		useState<CreateVideoCollectionInitialData>();

	const showErrorToast = useCallback(
		() =>
			toast({
				title: 'An error occurred',
				description: 'Check the console for more details',
			}),
		[toast],
	);

	if (isLoading) {
		return <Spinner />;
	}

	if (error || !data) {
		return <ErrorInspector JSON={error} />;
	}

	const allSelectedVideoIds = data.listVideoCollections.reduce<string[]>(
		(acc, vc) => {
			if (vc) {
				return [...acc, ...vc.videoIds];
			}
			return acc;
		},
		[],
	);

	return (
		<>
			<CreateVideoCollectionModal
				isOpen={isOpen}
				onClose={onClose}
				onSuccess={refetch}
				initialData={initialData}
			/>
			<SelectVideoModal
				isOpen={isSelectVideoOpen}
				onClose={onSelectVideoClose}
				filterVideoIds={allSelectedVideoIds}
				onChange={async (selectedVideoId) => {
					try {
						if (!videoCollectionContext) {
							return toast({
								title: 'Video collection context missing',
								status: 'warning',
							});
						}
						await mutateAsync(
							buildInput(videoCollectionContext, [
								...videoCollectionContext.videoIds,
								selectedVideoId,
							]),
						);
						toast({
							title: 'Video added to collection',
							status: 'success',
						});
						onClose();
					} catch (err) {
						console.error(err);
						showErrorToast();
					}
				}}
			/>
			<Box>
				{data.listVideoCollections.map((videoCollection, vci) => (
					<Box key={videoCollection.id} mt="50px">
						<Flex alignItems="center" backgroundColor={videoCollection.hex} p={2} borderRadius="10px" color='white'>
							<Menu>
								<MenuButton
									mr="10px"
									as={IconButton}
									size="sm"
									aria-label="Options"
									icon={<HamburgerIcon />}
								/>
								<MenuList color='black'>
									<MenuItem
										icon={<EditIcon />}
										onClick={() => {
											setInitialData(videoCollection);
											onOpen();
										}}
									>
										Edit
									</MenuItem>
									<MenuItem
										color="red"
										icon={<DeleteIcon />}
										onClick={async () => {
											try {
												await deleteVideoCollection({
													videoCollectionId:
														videoCollection.id,
												});
											} catch (err) {
												console.error(err);
												showErrorToast();
											}
										}}
									>
										Remove
									</MenuItem>
								</MenuList>
							</Menu>
							<Text size="lg" fontWeight="bold">
								{videoCollection.name}
							</Text>
							<Text
								ml="10px"
								hidden={!videoCollection.onboarding}
							>
								(Onboarding)
							</Text>
						</Flex>
						<Flex
							gap="20px"
							mt="30px"
							alignItems="center"
							flexWrap="nowrap"
							minW="100%"
							overflowX="scroll"
							pb="10px"
						>
							{videoCollection.videos?.map(
								(video, vi, videosArr) => {
									if (!video) {
										return null;
									}
									return (
										<VideoCard
											flexShrink={0}
											key={`${vci}-${video.id}-${vi}`}
											w="300px"
											{...video}
											footer={
												<ButtonGroup w="100%">
													<Button
														visibility={
															vi
																? 'visible'
																: 'hidden'
														}
														size="grow"
														variant="ghost"
														onClick={async () => {
															try {
																await mutateAsync(
																	buildInput(
																		videoCollection,
																		arrayMoveImmutable(
																			videoCollection.videoIds,
																			vi,
																			vi -
																				1,
																		),
																	),
																);
															} catch (err) {
																console.error(
																	err,
																);
																showErrorToast();
															}
														}}
													>
														&lt;
													</Button>
													<Button
														size="grow"
														variant="ghost"
														colorScheme="red"
														onClick={async () => {
															try {
																await mutateAsync(
																	buildInput(
																		videoCollection,
																		videoCollection.videoIds.filter(
																			(
																				a,
																				i,
																			) =>
																				i !==
																				vi,
																		),
																	),
																);
															} catch (err) {
																console.error(
																	err,
																);
																showErrorToast();
															}
														}}
													>
														Remove
													</Button>
													<Button
														visibility={
															vi !==
															videosArr.length - 1
																? 'visible'
																: 'hidden'
														}
														variant="ghost"
														size="grow"
														onClick={async () => {
															try {
																await mutateAsync(
																	buildInput(
																		videoCollection,
																		arrayMoveImmutable(
																			videoCollection.videoIds,
																			vi,
																			vi +
																				1,
																		),
																	),
																);
															} catch (err) {
																console.error(
																	err,
																);
																showErrorToast();
															}
														}}
													>
														&gt;
													</Button>
												</ButtonGroup>
											}
										/>
									);
								},
							)}

							<Button
								flexShrink={0}
								variant="outline"
								onClick={() => {
									setVideoCollectionContext(videoCollection);
									onSelectVideoOpen();
								}}
							>
								+ Add video to collection
							</Button>
						</Flex>
					</Box>
				))}
				<Button
					mt="100px"
					display="block"
					mx="auto"
					onClick={() => {
						setInitialData(undefined);
						onOpen();
					}}
				>
					Create Video Collection
				</Button>
			</Box>
		</>
	);
};

export default VideoCollections;
