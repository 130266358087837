import React, { PropsWithChildren, useEffect, useState } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import {
	SongWeight as SongWeightGraphQLSchema,
	useDeleteSongWeightMutation,
	useDeleteVideoMutation,
	useGetSongWeightQuery,
	useListSongWeightsQuery,
	useListVideosQuery,
	useUpdateSongWeightMutation,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { SongWeightInputSchema, VideoInputSchema } from '@/graphql/validation';
import {
	Box,
	Button,
	ButtonGroup,
	Flex,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	Stack,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import { omit } from 'ramda';
import { useParams } from 'react-router';
import Editor from 'react-simple-code-editor';
import { useLocalStorage } from 'react-use';

import { LOCAL_STORAGE_ITEM_KEY } from '../AISettings';

//Example style, you can use another

const songWeightInputSchema = SongWeightInputSchema();
const initialValues: SongWeightGraphQLSchema = {
	id: '',
	name: '',
	description: '',
	function: '',
	highValue: 0,
	lowValue: 0,
	key: '',
};

export type CreateUpdateWeightValues = typeof initialValues;

const SongWeight = () => {
	const { id } = useParams();
	const toast = useToast();
	const [testItem] = useLocalStorage<null | string>(
		LOCAL_STORAGE_ITEM_KEY,
		null,
	);
	const [request, setRequest] = useLocalStorage('request-body', '{}');

	const {
		handleSubmit,
		handleChange,
		setFieldValue,
		values,
		errors,
		setValues,
		validateForm,
	} = useFormik({
		initialValues,
		onSubmit: async (values, formikHelpers) => {
			try {
				const input = {
					...omit(['id'], values),
					lowValue: parseInt(values.lowValue.toString()),
					highValue: parseInt(values.highValue.toString()),
				};

				await updateMutateAsync({
					input,
					weightId: values.id,
				});

				toast({
					description: 'Updated',
				});
			} catch (err) {
				console.error(err);
			} finally {
				formikHelpers.setSubmitting(false);
			}
		},
	});
	const { data, error, isLoading } = useGetSongWeightQuery(
		{
			weightId: id || '',
		},
		{
			enabled: !!id,
			onSuccess: (data) => {
				setValues(data.getSongWeight);
			},
		},
	);

	const {
		mutateAsync: updateMutateAsync,
		error: updateError,
		isLoading: updateLoading,
	} = useUpdateSongWeightMutation<FetchError>();

	const [result, setResult] = useState<object | null>(null);
	const [testContext, setTestContext] = useState<object | undefined>();

	const onTest = () => {
		setResult(null);
		setTestContext(undefined);
		if (!testItem) {
			toast({
				description:
					'No test item set. Go to /ai/ and set a test item first.',
			});
			return;
		}
		validateForm();
		let weight = 0;
		let error: string | undefined = undefined;
		const itemKey = values.key;
		const weightFunction = evalWithCatch(values.function);
		if (typeof weightFunction === 'function') {
			try {
				const testItemParsed = JSON.parse(testItem);
				const testItemValue = testItemParsed[itemKey];
				if (testItemValue === undefined) {
					throw new Error(
						"The function's key does not match any values in the test item's list of fields.",
					);
				}
				const runParams = {
					value: testItemParsed[itemKey],
					record: testItemParsed,
					key: itemKey,
					request: JSON.parse(request || '{}'),
					lowValue: parseInt(values.lowValue.toString()) || 0,
					highValue: parseInt(values.highValue.toString()) || 0,
				};
				setTestContext(runParams);
				weight = weightFunction(runParams, context, true);
			} catch (err) {
				error =
					`${err}` ||
					`There was an error while calculating the weight`;
			}
		}
		setResult({
			value: testItem[itemKey],
			weight,
			error,
		});
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (error || !data) {
		throw error;
	}

	return (
		<>
			<form onSubmit={handleSubmit}>
				<Stack spacing={4}>
					<FormikField
						type="text"
						name="name"
						handleChange={handleChange}
						value={values.name}
						isRequired
					/>

					<FormikField
						type="text"
						name="description"
						handleChange={handleChange}
						value={values.description}
						isRequired
					/>
					<FormikField
						type="text"
						name="key"
						handleChange={handleChange}
						value={values.key}
						isRequired
					/>

					<FormikField
						type="number"
						label="Low Value (in seconds)"
						name="lowValue"
						handleChange={handleChange}
						value={values.lowValue}
						isRequired
					/>

					<FormikField
						type="number"
						label="High Value (in seconds)"
						name="highValue"
						handleChange={handleChange}
						value={values.highValue}
						isRequired
					/>
				</Stack>

				<Text size="md" mt={6}>
					Function code:
				</Text>
				<Text mt={2}>
					The <code>function</code> takes two arguments. The first one
					is the <code>data</code> arugment. It is an object with the
					following keys:
				</Text>
				<Text p={1}>
					<code>value</code> The cell value <br />
					<code>request</code> The request body <br />
					<code>key</code> The name of the key as a string <br />
					<code>record</code> A reference to the current row with all
					its data <br />
					<code>lowValue</code> The low value of the weight record.
					Use this instead of hardcoding any scoring numbers for
					easier weight tweaking. <br />
					<code>highValue</code> The high value of the weight record.
					Use this instead of hardcoding any scoring numbers for
					easier weight tweaking. <br />
				</Text>
				<Text>
					The function's second argument is <code>context</code>. The{' '}
					<code>context</code> contains helpful function that can be
					accessed as regular object keys.
				</Text>
				<Text p={1}>
					<code>toNum</code> A function that takes in a{' '}
					<code>string | number | null</code> and returns a{' '}
					<code>number</code> <br />
					<code>toStrArr</code> A function that takes in an array of{' '}
					<code>(string | number | null)[]</code> and returns a{' '}
					<code>string[]</code> <br />
					<code>toArr</code> A function that takes in a{' '}
					<code>null | unknown[]</code> and returns{' '}
					<code>unknown[]</code> <br />
				</Text>

				<Editor
					id="function"
					value={values.function}
					onValueChange={(code) => setFieldValue('function', code)}
					highlight={(code) => highlight(code, languages.js, 'js')}
					padding={10}
					style={{
						fontFamily: '"Fira code", "Fira Mono", monospace',
						fontSize: 12,
						border: '1px solid gray',
					}}
				/>

				<Editor
					value={request || '{}'}
					onValueChange={(code) => setRequest(code)}
					highlight={(code) => highlight(code, languages.js, 'json')}
					padding={10}
					style={{
						fontFamily: '"Fira code", "Fira Mono", monospace',
						fontSize: 12,
						border: '1px solid gray',
					}}
				/>

				<ErrorInspector
					JSON={{
						result,
						testContext,
						form: {
							errors,
							request: updateError && updateError.toJSON(),
						},
					}}
				/>

				<ButtonGroup my={2} w="100%" spacing={2}>
					<Button onClick={onTest}>Test</Button>
					<Button
						isDisabled={updateLoading}
						variant="outline"
						type="submit"
					>
						Save
					</Button>
				</ButtonGroup>
			</form>
		</>
	);
};

export default SongWeight;

export type WeightsFunctionArguments<T> = {
	record: T;
	key: string;
	request: Record<string, any>;
	lowValue: number;
	highValue: number;
};

export type Context = {
	toNum: (value?: string | number | null) => number;
	toStrArr: (value?: (string | null | undefined)[]) => string[];
	toArr: <T>(value?: T[] | null) => T[];
};

export type WeightsFunction<T> = (
	args: WeightsFunctionArguments<T>,
	ctx: Context,
	console?: any,
) => number;

// Take a thing and test to see if it is something (not null / undefined)
// The `maybeThing is T` means this can be used in an Array.filter(...) to remove nulls / undefineds with type safety
export const isSomething = (
	maybeThing: any | null | undefined,
): maybeThing is any => maybeThing !== null && maybeThing !== undefined;

/**
 * Takes an array or undefined; returns the array or empty array of the same type
 * If it is an array, cleans the array so it isSomething, so we can return T[]
 */
export const alwaysArray = (
	maybeArray: (any | null | undefined)[] | null | undefined,
): any[] => (Array.isArray(maybeArray) ? maybeArray.filter(isSomething) : []);

export const context: Context = {
	toNum: (value) => {
		if (!value) return 0;
		return parseFloat(value.toString());
	},
	toStrArr: (value) => {
		if (Array.isArray(value)) {
			const result = alwaysArray(value.map((v) => v?.toString()));
			// console.log("toStrArr", { value, result })
			return result;
		} else {
			// console.error("toStrArr() Value is not Array" , { value })
		}
		return [];
	},
	toArr: (value) => {
		const result = alwaysArray(value);
		// console.log("toArr", { value, result })
		return result;
	},
};

const mockConsole = {
	log: () => void 0,
	error: () => void 0,
	info: () => void 0,
};

export const evalWithCatch = <T,>(fnString: string) => {
	return (
		args: WeightsFunctionArguments<T>,
		ctx: Context,
		withConsole = false,
	) => {
		const test = eval(fnString) as WeightsFunction<T>;
		return test(args, ctx, withConsole ? console : mockConsole);
	};
};
