import React, { PropsWithChildren } from 'react';

import { Box, Center, Container, Image } from '@chakra-ui/react';

export const PublicLayout: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<Container>
			<Center h="100vh" flexDirection="column">
				<Image w="120px" src="/asaph-logo-beta-black.svg" />
				<Box mt="40px" w={{ base: '100%', sm: '500px' }}>
					{children}
				</Box>
			</Center>
		</Container>
	);
};
