import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { JsonViewer } from '@textea/json-viewer';

type ErrorInspectorProps = BoxProps & {
	JSON: object;
};

const ErrorInspector: React.FC<ErrorInspectorProps> = ({ JSON, ...props }) => {
	return (
		<Box mt="20px" {...props}>
			<JsonViewer
				value={JSON}
				quotesOnKeys={false}
				displayDataTypes={false}
				displayObjectSize={false}
				enableClipboard={false}
        defaultInspectDepth={1}
			/>
		</Box>
	);
};

export default ErrorInspector;
