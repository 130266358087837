import React from 'react';

import { FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/react';
import { capitalCase } from 'change-case';

type FormikFieldProps = {
	handleChange: (args: any) => any;
	value: any;
	type: React.HTMLInputTypeAttribute;
	name: string;
	label?: string;
	isRequired?: boolean;
  placeholder?: string;
  helperText?: string;
};

const FormikField: React.FC<FormikFieldProps> = ({
	handleChange,
	value,
	type,
	name,
	label,
	isRequired = false,
  placeholder,
  helperText,
}) => {
	return (
		<FormControl isRequired={isRequired}>
			<FormLabel>{label || capitalCase(name)}</FormLabel>
			<Input
        placeholder={placeholder}
				type={type}
				name={name}
				onChange={handleChange}
				value={value}
			/>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};

export default FormikField;
