import React, { useMemo } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import {
	ListContributorsQuery,
	useCreateVideoMutation,
	useListContributorsQuery,
	useUpdateVideoMutation,
	VideoInput,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { VideoInputSchema } from '@/graphql/validation';
import {
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { omit } from 'ramda';

const videoInputSchema = VideoInputSchema();
const initialValues: Omit<VideoInput, 'duration' | 'url'> & {
	id: string;
	duration: string;
	url?: string | null;
} = {
	id: '',
	description: '',
	duration: '0',
	name: '',
	paid: false,
	posterUrl: '',
	thumbnailUrl: '',
	url: '',
	contributorId: '',
};

export type CreateVideoInitialValues = typeof initialValues;

type CreateVideoModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
	initialData?: typeof initialValues;
};

const CreateVideoModal: React.FC<CreateVideoModalProps> = ({
	isOpen,
	onClose,
	onSuccess,
	initialData = initialValues,
}) => {
	const {
		mutateAsync,
		error: createVideoError,
		isLoading: createVideoLoading,
	} = useCreateVideoMutation<FetchError>({
		onSuccess: onSuccess,
	});
	const {
		mutateAsync: updateMutateAsync,
		error: updateVideoError,
		isLoading: updateVideoLoading,
	} = useUpdateVideoMutation<FetchError>({
		onSuccess: onSuccess,
	});
	const {
		data: contributorsData,
		error: contributorsError,
		isLoading: contributorsLoading,
	} = useListContributorsQuery<ListContributorsQuery, FetchError>();

	const contributors = useMemo(
		() =>
			(contributorsData?.listContributors.filter(
				(c) => !!c,
			) as NonNullable<ListContributorsQuery['listContributors'][0]>[]) ||
			[],
		[contributorsData],
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{initialData.id ? 'Update' : 'Create'} Video
				</ModalHeader>
				<ModalCloseButton />
				<Formik
					key="asaphSignUn"
					initialValues={initialData}
					validationSchema={videoInputSchema}
					onSubmit={async (values, formikHelpers) => {
						try {
							const input = {
								...omit(['id', 'contributor'], values),
								url: values.url || '',
								duration: parseInt(values.duration),
							};
							if (initialData.id) {
								await updateMutateAsync({
									input,
									videoId: initialData.id,
								});
							} else {
								await mutateAsync({ input });
							}
							onClose();
						} catch (err) {
							console.error(err);
						} finally {
							formikHelpers.setSubmitting(false);
						}
					}}
				>
					{({
						handleSubmit,
						handleChange,
						values,
						isSubmitting,
						touched,
						errors,
					}) => (
						<form onSubmit={handleSubmit}>
							<ModalBody>
								<Stack spacing={4}>
									<FormikField
										type="text"
										name="name"
										handleChange={handleChange}
										value={values.name}
										isRequired
									/>

									<FormControl>
										<FormLabel>Contributor</FormLabel>

										{!!contributorsError && (
											<ErrorInspector
												JSON={contributorsError}
											/>
										)}
										{!!contributors.length && (
											<Select
												value={
													values.contributorId ||
													undefined
												}
												name="contributorId"
												onChange={handleChange}
												placeholder="Select Contributor"
											>
												{contributors.map(
													(contributor) => (
														<option
															selected={
																initialData.contributorId ===
																contributor.id
															}
															value={
																contributor.id
															}
														>
															{`${contributor.name} (${contributor.role})`}
														</option>
													),
												)}
											</Select>
										)}
									</FormControl>
									<FormikField
										type="text"
										name="url"
										handleChange={handleChange}
										value={values.url}
										isRequired
									/>
									<FormikField
										type="text"
										name="posterUrl"
										handleChange={handleChange}
										value={values.posterUrl}
										isRequired
									/>
									<FormikField
										type="text"
										name="thumbnailUrl"
										handleChange={handleChange}
										value={values.thumbnailUrl}
										isRequired
									/>

									<FormControl>
										<FormLabel>Is Paid?</FormLabel>

										<Checkbox
											name="paid"
											placeholder="paid"
											onChange={handleChange}
											isChecked={values.paid}
										>
											Paid
										</Checkbox>
									</FormControl>
									<FormikField
										type="number"
										label="Duration (in seconds)"
										name="duration"
										handleChange={handleChange}
										value={values.duration}
										isRequired
									/>

									<FormControl>
										<FormLabel>Description</FormLabel>

										<Textarea
											name="description"
											placeholder="description"
											onChange={handleChange}
											value={values.description || ''}
										/>
									</FormControl>
								</Stack>

								<ErrorInspector
									JSON={{
										form: errors,
										request: initialData.id
											? updateVideoError &&
											  updateVideoError.toJSON()
											: createVideoError &&
											  createVideoError.toJSON(),
									}}
								/>
							</ModalBody>
							<ModalFooter>
								<ButtonGroup w="100%" spacing={2}>
									<Button type="submit">
										{initialData.id ? 'Update' : 'Create'}
									</Button>
									<Button
										variant="ghost"
										mr={3}
										onClick={onClose}
									>
										Close
									</Button>
								</ButtonGroup>
							</ModalFooter>
						</form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default CreateVideoModal;
