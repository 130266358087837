import { defineStyleConfig } from '@chakra-ui/react';

const Heading = defineStyleConfig({
	// The styles all button have in common
	// baseStyle: {
	//   fontSize: '50px',
	//   color: 'green',
	// },
	// Two sizes: sm and md
	sizes: {
		h1: {
			fontSize: { base: '30px', md: '40px' },
			lineHeigh: 'normal',
			fontWeight: 300,
		},
		h2: {
			fontSize: { base: '20px', md: '30px' },
			lineHeigh: 'normal',
			fontWeight: 300,
		},
	},
	// Two variants: outline and solid
	// variants: {
	//   outline: {
	//     border: '2px solid',
	//     borderColor: 'purple.500',
	//     color: 'purple.500',
	//   },
	//   solid: {
	//     bg: 'purple.500',
	//     color: 'white',
	//   },
	// },
	// The default size and variant values
	defaultProps: {
		size: 'h1',
	},
});

export default Heading;
