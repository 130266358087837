import React from 'react';

import { HStack, Tag, Wrap } from '@chakra-ui/react';

export type TagItem = {
	label: string;
	color?: string;
	selected?: boolean;
};

export const RenderChips: React.FC<{
	items: TagItem[];
	onChange?: (selectedIndex: number, label: string, value: boolean) => void;
}> = ({ items, onChange }) => {
	const noneSelected = Boolean(items.filter((i) => i.selected).length);
	return (
		<Wrap>
			{items.map((t, i) => {
				const isDisabled = noneSelected && !t.selected;
				return (
					<Tag
						key={`${i}-${t}`}
						cursor="pointer"
						variant={isDisabled ? 'outline' : 'solid'}
						onClick={() => {
							if (onChange) {
								onChange(i, t.label, !!t.selected);
							}
						}}
					>
						{t.label}
					</Tag>
				);
			})}
		</Wrap>
	);
};
