import { defineStyleConfig } from '@chakra-ui/react';

const Button = defineStyleConfig({
	// The styles all button have in common
	baseStyle: {
		fontWeight: 'bold',
		textTransform: 'uppercase',
		borderRadius: 'base', // <-- border radius is same for all variants and sizes
		paddingInlineStart: '0px',
		paddingInlineEnd: '0px',
	},
	// Two sizes: sm and md
	sizes: {
		sm: {
			fontSize: '12px',
			px: 4, // <-- px is short for paddingLeft and paddingRight
			py: 3, // <-- py is short for paddingTop and paddingBottom
		},
		md: {
			fontSize: { base: '12px', md: '14px' },
			fontWeight: '500',
			py: '12px', // <-- these values are tokens from the design system
			px: { base: '28px', md: '48px' }, // <-- these values are tokens from the design system
			borderRadius: 0,
		},
		grow: {
			py: '12px', // <-- these values are tokens from the design system
			fontSize: { base: '12px', md: '14px' },
			fontWeight: '500',
			flex: '1',
		},
	},
	// Two variants: outline and solid
	variants: {
		outline: {
			border: '2px solid',
			borderRadius: '8px',
		},
		solid: {
			borderRadius: '8px',
		},
	},
	// The default size and variant values
	defaultProps: {
		size: 'md',
		variant: 'solid',
		colorScheme: 'brand',
	},
});

export default Button;
