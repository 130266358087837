import { defineStyleConfig } from '@chakra-ui/react';

const Text = defineStyleConfig({
	// The styles all button have in common
	baseStyle: {
		fontSize: '12px',
	},
	// Two sizes: sm and md
	sizes: {
		sm: {
			fontSize: '12px',
			lineHeight: 'normal',
			fontWeight: 300,
		},
		md: {
			fontSize: '14px',
			lineHeight: 'normal',
			fontWeight: 'normal',
		},
		lg: {
			fontSize: { base: '16px', sm: '20px' },
			lineHeight: 'normal',
			fontWeight: 300,
		},
	},
	// Two variants: outline and solid
	// variants: {
	//   outline: {
	//     border: '2px solid',
	//     borderColor: 'purple.500',
	//     color: 'purple.500',
	//   },
	//   solid: {
	//     bg: 'purple.500',
	//     color: 'white',
	//   },
	// },
	// The default size and variant values
	// defaultProps: {
	//   size: 'md',
	//   variant: 'outline',
	// },
});

export default Text;
