const colors = {
	transparent: 'transparent',
	black: '#000',
	white: '#fff',
	brand: {
		'50': '#b5c4ff',
		'100': '#b5c4ff',
		'200': '#b5c4ff',
		'300': '#4266fd',
		'400': '#1340fd',
		'500': '#1340fd',
		'600': '#1340fd',
		'700': '#1340fd',
		'800': '#1340fd',
		'900': '#1340fd',
	},
	yellow: {
		'50': '#FFFBE5',
		'100': '#FFF4B8',
		'200': '#FFED8A',
		'300': '#FFE65C',
		'400': '#FFDF2E',
		'500': '#ffe348',
		'600': '#CCAD00',
		'700': '#998200',
		'800': '#665600',
		'900': '#332B00',
	},
	grey: {
		200: '#d6d6e7',
		900: '#23263b',
	},
	// ...
};

export default colors;
