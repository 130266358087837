import React from 'react';

import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardFooter,
	CardProps,
	Divider,
	Image,
	Text,
} from '@chakra-ui/react';

type VideoCardProps = CardProps & {
	id: string;
	name: string;
	thumbnailUrl?: string;
  posterUrl?: string;
	url?: string | null;
	footer?: React.ReactNode;
	contributor?: {
		name: string;
		imageUrl?: string | null;
	} | null;
};

export const videoCardFieldKeys = [
	'id',
	'name',
	'thumbnailUrl',
  'posterUrl',
	'url',
	'contributor',
];

const VideoCard: React.FC<VideoCardProps> = ({
	id,
	name,
	thumbnailUrl,
  posterUrl,
	url,
	footer,
	contributor,
	...props
}) => {
	return (
		<Card {...props} display="inline-flex">
			<CardBody position="relative">
				{!!contributor && contributor.imageUrl && (
					<Image
						position="absolute"
						w="50px"
						h="50px"
						top="30px"
						left="30px"
						borderRadius="100%"
						border="1px solid white"
						src={contributor.imageUrl}
					/>
				)}
				<Image src={thumbnailUrl || posterUrl || ''} />
				<Text mt="10px">{name}</Text>
			</CardBody>

			{footer && (
				<>
					<Divider borderColor="brand.200" />
					<CardFooter>{footer}</CardFooter>
				</>
			)}
		</Card>
	);
};

export default VideoCard;
