import React, { Fragment } from 'react';

import { PipelineJob } from '@/graphql';
import { timeFormatter } from '@/helpers/timeFormatter';
import { useMediaDomainUrl } from '@/hooks/useMediaDomainUrl';
import {
	Box,
	BoxProps,
	Button,
	Card,
	CardProps,
	Image,
	Input,
	Skeleton,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Tr,
} from '@chakra-ui/react';
import { pathOr } from 'ramda';

interface IProps {
	job: PipelineJob;
	onClick(videoUrl: string): void;
}

const videoProps = new Map<string, string>([
	['Status', 'workflowStatus'],
	['Video', 'srcVideo'],
	['Started', 'startTime'],
	['Finished', 'endTime'],
	['Duration', 'duration'],
	['GUID', 'guid'],
	['HLS URL', 'hlsUrl'],
	['Src File', 'srcVideo'],
	['Dest Files', 'guid'],
	['DB Item', 'guid'],
]);

const videoThumbnailSize = {
	width: 224,
	height: 400,
};

export const AdminJobTile: React.FC<IProps & CardProps> = ({
	job,
	onClick,
	...props
}) => {
	const signedThumnailUrl = useMediaDomainUrl(
		pathOr('/', ['thumbNailsUrls'], job),
	);
	const signedHlsUrl = useMediaDomainUrl(pathOr('/', ['hlsUrl'], job));
	return (
		<Card {...props} w={videoThumbnailSize.width}>
			<Box
				onClick={() => {
					onClick(signedHlsUrl);
				}}
			>
				<Image
					mx="auto"
					src={signedThumnailUrl}
					alt=""
					className="admin_jobs_tile-thumbnail-image"
					{...videoThumbnailSize}
					loading="lazy"
				/>
			</Box>
			<div className="admin_jobs_tile-content">
				<TableContainer>
					<Table variant="simple" size="sm">
						<Tbody>
							{Array.from(videoProps.keys()).map((key) => {
								const attribute = videoProps.get(key);
								if (attribute) {
									let value: String | React.ReactNode =
										pathOr('—', [attribute], job);
									if (value !== '—') {
										switch (key) {
											case 'Duration':
												// value = timeFormatter(
												// 	parseInt(`${value}`),
												// );
												value = `${value} seconds`;
												break;
											case 'Src File':
												value = (
													<a
														href={`https://s3.console.aws.amazon.com/s3/object/asaph-vod-pipeline-source-1bq9iud2toxmu?region=ap-southeast-2&prefix=${value}`}
														target="_blank"
														rel="noreferrer"
													>
														Link
													</a>
												);
												break;
											case 'Dest Files':
												value = (
													<a
														href={`https://s3.console.aws.amazon.com/s3/buckets/asaph-vod-pipeline-destination-r8cygx29axek?region=ap-southeast-2&prefix=${value}/&showversions=false`}
														target="_blank"
														rel="noreferrer"
													>
														Link
													</a>
												);
												break;
											case 'DB Item':
												value = (
													<a
														href={`https://ap-southeast-2.console.aws.amazon.com/dynamodbv2/home?region=ap-southeast-2#edit-item?table=asaph-vod-pipeline&itemMode=2&pk=${value}&sk&ref=%23item-explorer%3Ftable%3Dasaph-vod-pipeline&route=ROUTE_ITEM_EXPLORER`}
														target="_blank"
														rel="noreferrer"
													>
														Link
													</a>
												);
												break;
											case 'HLS URL':
												if (typeof value === 'string') {
													value = (
														<Input
															type="text"
															value={value}
															readOnly
														/>
													);
												}
												break;
											default:
												break;
										}
									}
									return (
										<Tr key={key}>
											<Td>{key}</Td>
											<Td>{value}</Td>
										</Tr>
									);
								}
								return null;
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</div>
		</Card>
	);
};
