import React, {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';

import { GetMyUserQuery } from '@/graphql';
import { useEnvironment } from '@/providers/EnvironmentProvider';
import { useToast } from '@chakra-ui/react';
import { gql, request } from 'graphql-request';
import { isNil } from 'ramda';
import { useLocalStorage } from 'react-use';

export type User = {
	email: string;
	id: string;
	displayName?: string | null;
};

interface AuthContext {
	isLoading: boolean;
	isAuthenticated: boolean;
	token: string;
	user: User;
	setUser: Dispatch<SetStateAction<User>>;
	authenticate: (jwt: string) => void;
	logout: () => void;
}

const getMyUserQuery = gql`
	query getMyUser {
		getMyUser {
			id
			displayName
			email
		}
	}
`;

const defaultUserValue: User = {
	email: '',
	id: '',
	displayName: '',
};

const defaultValue: AuthContext = {
	isLoading: false,
	isAuthenticated: false,
	token: '',
	user: defaultUserValue,
	setUser: () => {},
	authenticate: () => {},
	logout: () => {},
};

const AuthContext = createContext<AuthContext>(defaultValue);

export function useAuth(): AuthContext {
	const context = useContext(AuthContext);
	if (isNil(context)) {
		throw new Error(
			`useEnvironment must be used within an EnvironmentProvider`,
		);
	}

	return context;
}

type AuthProviderProps = {
	children: (data: AuthContext) => ReactNode | null;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
	children: renderChildren,
}) => {
	const {
		api: { url },
	} = useEnvironment();
	const [localStorageToken, setLocalStorageToken] = useLocalStorage<
		string | null
	>('asaphly-user', null);
	const [user, setUser] = useState<User>(defaultValue.user);
	const [token, setToken] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const toast = useToast();
	// const { setIsLoading: setIsAppLoading, finishedLoading } = useAppLoading();
	const authenticate = useCallback(
		async (jwt: string) => {
			setIsLoading(true);
			// setIsAppLoading(true);
			try {
				const data = await request<GetMyUserQuery>(
					`${url}/graphql`,
					getMyUserQuery,
					{},
					{ Authorization: `Bearer ${jwt}` },
				);

				if (!data || !data.getMyUser) {
					throw new Error('User could not be authorized');
				}

				const userData = data.getMyUser;

				setIsLoading(false);
				setToken(jwt);
				setIsAuthenticated(true);
				setUser(userData);
				setLocalStorageToken(jwt);
				toast({
					title: 'Successfully logged in',
					status: 'success',
				});
				// finishedLoading();
			} catch (err) {
				setLocalStorageToken(null);
				setIsAuthenticated(false);
				console.error(err);
				toast({
					title: 'There was an error with loading your user',
					status: 'warning',
				});
				throw new Error('Something went wrong');
			}
		},
		[url, setLocalStorageToken, toast],
	);

	const logout = () => {
		setToken('');
		setLocalStorageToken('');
		setIsLoading(false);
		setIsAuthenticated(false);
		toast({
			title: 'Successfully logged out',
			status: 'success',
		});
	};

	const state: AuthContext = {
		user,
		token,
		isLoading,
		isAuthenticated,
		setUser,
		authenticate,
		logout,
	};

	useEffect(() => {
		if (!isAuthenticated && localStorageToken && !isLoading) {
			authenticate(localStorageToken);
		}
	}, [localStorageToken, isAuthenticated, authenticate, isLoading]);

	if (!isAuthenticated && localStorageToken) {
		return null;
	}

	console.log('Rendering AuthProvider', state);

	return (
		<AuthContext.Provider value={state}>
			{renderChildren(state)}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
