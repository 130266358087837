import React, { PropsWithChildren, useCallback, useState } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import {
	ListAssetsQuery,
	ListVideoCollectionsQuery,
	useListAssetsQuery,
	useListVideoCollectionsQuery,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardFooter,
	Checkbox,
	Flex,
	IconButton,
	Image,
	Input,
	Spinner,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';

const Assets = () => {
	const { data, error, isLoading, refetch } = useListAssetsQuery<
		ListAssetsQuery,
		FetchError
	>();

	if (isLoading) {
		return <Spinner />;
	}

	if (error || !data) {
		return <ErrorInspector JSON={error} />;
	}

	console.log(data);

	return (
		<Flex flexWrap="wrap" gap="20px">
			{data.listAssets?.map((asset) => (
				<Card w="150px">
					<CardBody>
						<Image h="150px" objectFit="contain" src={asset.url} />
					</CardBody>
					<CardFooter flexDirection="column">
						<Text fontSize="10px" wordBreak="break-all">
							{asset.key}
						</Text>
						<Input
							p="5px"
							height="25px"
							mt="10px"
							fontSize="10px"
							type="text"
							wordBreak="break-all"
							value={asset.url}
						/>
					</CardFooter>
				</Card>
			))}
		</Flex>
	);
};

export default Assets;
