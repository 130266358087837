import React, { useState } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import {
	GenerateSetlistMutation,
	TeamSong,
	useGenerateSetlistMutation,
	useListTeamsQuery,
} from '@/graphql';
import {
	Box,
	Button,
	Center,
	Select,
	Spinner,
	Table,
	TableCaption,
	TableContainer,
	Tag,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
  Wrap,
} from '@chakra-ui/react';
import {
  AccessorFn,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import Editor from 'react-simple-code-editor';
import { useLocalStorage } from 'react-use';

type SetlistTeamSong =
	GenerateSetlistMutation['generateSetlist']['positions'][0];

const GenerateSetlist = () => {
	const [teamId, setTeamId] = useState('');
	const [requestSaved, setRequestSaved] = useLocalStorage<string>(
		'generate-setlist-request',
		'',
	);
	const [request, setRequest] = useState<string>(
		requestSaved ||
			JSON.stringify(
				{
					positions: [
						{
							energyTags: [],
						},
					],
				},
				null,
				4,
			),
	);
	const [requestInputError, setRequestInputError] = useState('');

	const {
		data: teamsQuery,
		isLoading: isTeamsLoading,
		error: teamsError,
	} = useListTeamsQuery();

	const {
		mutateAsync: mutateAsync,
		data: weightedTeamSongsMutation,
		isLoading: isWeightedTeamSongsLoading,
		error: weightedTeamSongsError,
	} = useGenerateSetlistMutation();

	const setlistResult = weightedTeamSongsMutation?.generateSetlist;

	return (
		<Box>
			<Select
				mb={2}
				disabled={isTeamsLoading}
				onChange={(e) => setTeamId(e.currentTarget.value)}
				placeholder="Select team"
			>
				{teamsQuery?.listTeams.map((t) => (
					<option value={t.id}>{t.name}</option>
				))}
			</Select>

			<Editor
				value={request}
				onValueChange={(code) => {
					setRequest(code);
					setRequestSaved(code);
				}}
				highlight={(code) => highlight(code, languages.js, 'json')}
				padding={10}
				style={{
					fontFamily: '"Fira code", "Fira Mono", monospace',
					fontSize: 12,
					border: '1px solid gray',
				}}
			/>

			<Button
				onClick={async () => {
					try {
						setRequestInputError('');
						const requestParsed = JSON.parse(request);
						await mutateAsync({
							request: requestParsed,
							teamId: teamId,
						});
					} catch (err) {
						if (err instanceof Error) {
							return setRequestInputError(err.toString());
						}
						console.log(err);
						setRequestInputError('There was an error');
					}
				}}
				mt={2}
				isDisabled={!request || !teamId}
			>
				Generate Setlist
			</Button>

			{requestInputError || teamsError || weightedTeamSongsError ? (
				<div>
					<div>There was an error</div>
					<ErrorInspector
						JSON={{
							requestInputError,
							teamsError,
							weightedTeamSongsError,
						}}
					/>
				</div>
			) : null}

			{isWeightedTeamSongsLoading && (
				<Center>
					<Spinner />
				</Center>
			)}
			{!!setlistResult && (
				<Box mt={5}>
					<TeamSongsTable data={setlistResult.positions} />
				</Box>
			)}
		</Box>
	);
};

export default GenerateSetlist;

const columnHelper = createColumnHelper<SetlistTeamSong>();

const accessorFn: (key: keyof TeamSong) => AccessorFn<SetlistTeamSong, any> = (key) => {
  return (row: SetlistTeamSong) => !!row ? row[key] : ''
}

const SongTags = ({ tags }: { tags?: string[] }) => {
  const safeTags = tags || [];
  return <Wrap>{safeTags.map(t => <Tag>{t}</Tag>)}</Wrap>
}

const TeamSongsTable: React.FC<{ data: SetlistTeamSong[] }> = ({ data }) => {
	const columns = [
    columnHelper.accessor(accessorFn('name'), {
      id: 'name',
			cell: (info) => info?.getValue() || <i>No songs to fill this position</i>,
			header: () => <span>Name</span>,
    }),
		columnHelper.accessor(accessorFn('artist'), {
			id: 'artist',
			cell: (info) => info.getValue(),
			header: () => <span>Artist</span>,
		}),
		columnHelper.accessor(accessorFn('energyTags'), {
			id: 'energyTags',
			cell: (info) => <SongTags tags={info.getValue()} />,
			header: () => <span>Energy Tags</span>,
		}),
		columnHelper.accessor(accessorFn('emotionTags'), {
      id: 'emotionTags',
			cell: (info) => <SongTags tags={info.getValue()} />,
			header: () => <span>Emotion Tags</span>,
		}),
		columnHelper.accessor(accessorFn('gospelTags'), {
      id: 'gospelTags',
			cell: (info) => <SongTags tags={info.getValue()} />,
			header: () => <span>Gospel Tags</span>,
		}),
		columnHelper.accessor(accessorFn('songTypeTags'), {
      id: 'songTypeTags',
			cell: (info) => <SongTags tags={info.getValue()} />,
			header: () => <span>Song Type Tags</span>,
		}),
		columnHelper.accessor(accessorFn('rotationStatus'), {
      id: 'rotationStatus',
			header: () => 'Rotation Status',
			cell: (info) => info.renderValue(),
		}),
	];
	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<TableContainer>
			<Table variant="simple">
        <TableCaption>List of TeamSongs based on weights and generation definition positions</TableCaption>
				<Thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<Th key={header.id}>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext(),
										  )}
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map((row) => (
						<Tr key={row.id}>
							{row.getVisibleCells().map((cell) => (
								<Td key={cell.id}>
									{flexRender(
										cell.column.columnDef.cell,
										cell.getContext(),
									)}
								</Td>
							))}
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
