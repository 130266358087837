import { useAuth } from '@/providers/AuthProvider';
import { useEnvironment } from '@/providers/EnvironmentProvider';
import { pathOr } from 'ramda';

export class FetchError extends Error {
	code: string;
	message: string;

	constructor(message, code) {
		super(message); // (1)
		this.code = code; // (2)
		this.message = message;
	}

	toJSON(): {
		code: string;
		message: string;
	} {
		return {
			code: this.code,
			message: this.message,
		};
	}
}

export const useFetchData = <TData, TVariables>(
	query: string,
	options?: RequestInit['headers'],
): ((variables?: TVariables) => Promise<TData>) => {
	// it is safe to call React Hooks here.
	const {
		api: { url },
	} = useEnvironment();
	const { token } = useAuth();

	return async (variables?: TVariables) => {
		const res = await fetch(`${url}/graphql`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				query,
				variables,
			}),
		});

		const json = await res.json();

		if (json.errors) {
			const code = pathOr(
				'INTERNAL_SERVER_ERROR',
				[0, 'extensions', 'code'],
				json.errors,
			);
			const message = pathOr(
				'An error occurrred',
				[0, 'message'],
				json.errors,
			);
			throw new FetchError(message, code);
		}

		return json.data;
	};
};
