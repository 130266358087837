import React, { PropsWithChildren, useCallback } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import VideoCard from '@/components/shared/VideoCard';
import { videoCardFieldKeys } from '@/components/shared/VideoCard/VideoCard';
import {
	GetDashboardQuery,
	UpdateDashboardMutationVariables,
	useCreateDashboardMutation,
	useCreateVideoMutation,
	useGetDashboardQuery,
	useListVideoCollectionsQuery,
	useListVideosQuery,
	useUpdateDashboardMutation,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { VideoInputSchema } from '@/graphql/validation';
import { useAuth } from '@/providers/AuthProvider';
import {
	AddIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	DeleteIcon,
	HamburgerIcon,
} from '@chakra-ui/icons';
import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	IconButton,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Spinner,
	Stack,
	Text,
	Textarea,
	useDisclosure,
	useToast,
	Wrap,
} from '@chakra-ui/react';
import { arrayMoveImmutable } from 'array-move';
import { pick } from 'ramda';

import SelectVideoCollectionModal from './components/SelectVideoCollectionModal';

const buildInput = (
	videoCollectionIds: string[],
): UpdateDashboardMutationVariables => ({
	input: {
		videoCollectionIds: videoCollectionIds,
	},
});

const Dashboard = () => {
	const toast = useToast();
	const { data, error, isLoading, refetch } = useGetDashboardQuery<
		GetDashboardQuery,
		FetchError
	>();
	const {
		mutateAsync,
		isLoading: isCreateLoading,
		error: createError,
	} = useCreateDashboardMutation({
		onSuccess: () => {
			refetch();
		},
	});
	const {
		mutateAsync: updateDashboard,
		isLoading: isUpdateLoading,
		error: updateError,
	} = useUpdateDashboardMutation({
		onSuccess: () => {
			refetch();
		},
	});
	const { isOpen, onOpen, onClose } = useDisclosure();

	const showErrorToast = useCallback(
		() =>
			toast({
				title: 'An error occurred',
				description: 'Check the console for more details',
			}),
		[toast],
	);

	if (isLoading || isCreateLoading) {
		return <Spinner />;
	}

	if (error || !data) {
		if (error?.code === 'NOT_FOUND') {
			return (
				<Box>
					<Text>
						A Dashboard does not exist yet, please create one with
						the button below!
					</Text>
					<Button
						mt="20px"
						onClick={() => mutateAsync(buildInput([]))}
					>
						Create Dashboard
					</Button>
				</Box>
			);
		}
		throw error;
	}

	if (createError) {
		throw createError;
	}

	const dashboardVideoCollections = data.getDashboard.videoCollections || [];
	const dashboardVideoCollectionIds = dashboardVideoCollections.map(
		(dvc) => dvc.id,
	);

	return (
		<>
			<SelectVideoCollectionModal
				isOpen={isOpen}
				onClose={onClose}
				existingVideoCollectionIds={dashboardVideoCollectionIds}
				onSuccess={async (selectedVideoCollectionId) => {
					try {
						await updateDashboard({
							input: {
								videoCollectionIds: [
									...dashboardVideoCollections.map(
										(dvc) => dvc.id,
									),
									selectedVideoCollectionId,
								],
							},
						});
					} catch (err) {
						console.error(err);
						showErrorToast();
					}
				}}
			/>
			<Box>
				{dashboardVideoCollections.map((videoCollection, vci) => (
					<Box key={videoCollection.id} mt="50px">
						<Flex alignItems="center">
							<Menu>
								<MenuButton
									mr="10px"
									as={IconButton}
									size="sm"
									aria-label="Options"
									icon={<HamburgerIcon />}
								/>
								<MenuList>
									{vci !== 0 && (
										<MenuItem
											icon={<ChevronUpIcon />}
											onClick={async () => {
												try {
													await updateDashboard(
														buildInput(
															arrayMoveImmutable(
																dashboardVideoCollectionIds,
																vci,
																vci - 1,
															),
														),
													);
												} catch (err) {
													console.error(err);
													showErrorToast();
												}
											}}
										>
											Move up
										</MenuItem>
									)}
									{dashboardVideoCollections.length - 1 !==
										vci && (
										<MenuItem
											icon={<ChevronDownIcon />}
											onClick={async () => {
												try {
													await updateDashboard(
														buildInput(
															arrayMoveImmutable(
																dashboardVideoCollectionIds,
																vci,
																vci + 1,
															),
														),
													);
												} catch (err) {
													console.error(err);
													showErrorToast();
												}
											}}
										>
											Move down
										</MenuItem>
									)}
									<MenuItem
										color="red"
										icon={<DeleteIcon />}
										onClick={async () => {
											try {
												await updateDashboard(
													buildInput(
														dashboardVideoCollectionIds.filter(
															(_dvc, dvci) =>
																dvci !== vci,
														),
													),
												);
											} catch (err) {
												console.error(err);
												showErrorToast();
											}
										}}
									>
										Remove
									</MenuItem>
								</MenuList>
							</Menu>
							<Text size="lg" fontWeight="bold">
								{videoCollection.name}
							</Text>
							<Text
								ml="10px"
								hidden={!videoCollection.onboarding}
							>
								(Onboarding)
							</Text>
						</Flex>
						<Flex
							gap="20px"
							mt="30px"
							alignItems="center"
							flexWrap="nowrap"
							minW="100%"
							overflowX="scroll"
							pb="10px"
						>
							{videoCollection.videos?.map(
								(video, vi, videosArr) => {
									if (!video) {
										return null;
									}
									return (
										<VideoCard
											flexShrink={0}
											key={`${vci}-${video.id}-${vi}`}
											w="300px"
											{...pick(videoCardFieldKeys, video)}
										/>
									);
								},
							)}
						</Flex>
					</Box>
				))}
				<Button my="100px" mx="auto" display="block" onClick={onOpen}>
					Add video collection
				</Button>
			</Box>
		</>
	);
};

export default Dashboard;
