import Button from './button';
import Container from './container';
import Heading from './heading';
import Text from './text';

const components = {
	Container,
	Heading,
	Text,
	Button,
};

export default components;
