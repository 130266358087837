import { useEnvironment } from '@/providers/EnvironmentProvider';

function safeEncodeUri(uri: string) {
	return uri.replace(/'/g, '%27').replace(/\+/g, '%2B');
}

export function useMediaDomainUrl(path: string): string {
	const { mediaDomainRoot } = useEnvironment();
	// Remove first slash in the path if present
	if (path.length && path[0] === '/') {
		path = path.slice(1, path.length);
	}
	return `${mediaDomainRoot}${safeEncodeUri(encodeURI(path))}`;
}
