import * as React from 'react';

import theme from '@/theme';
import {
	ChakraProvider,
	ColorModeScript,
	createStandaloneToast,
} from '@chakra-ui/react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { EnvironmentProvider } from './providers/EnvironmentProvider';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);
const { ToastContainer } = createStandaloneToast();

root.render(
	<ChakraProvider theme={theme}>
		<EnvironmentProvider>
			<BrowserRouter>
				<ColorModeScript />
				<App />
				<ToastContainer />
			</BrowserRouter>
		</EnvironmentProvider>
	</ChakraProvider>,
);
