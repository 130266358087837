import React from 'react';

import { useEnvironment } from '@/providers/EnvironmentProvider';
import { Button, Heading, HStack, Input, Text, VStack } from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

const signUpValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email('Must be a valid email address')
		.required('Email address is required'),
});

const EmailForm: React.FC<{ onChange: (email: string) => void }> = ({
	onChange,
}) => {
	const {
		api: { url },
	} = useEnvironment();

	return (
		<Formik
			key="asaphSignUn"
			initialValues={{
				email: '',
				general: '',
			}}
			validationSchema={signUpValidationSchema}
			onSubmit={async (values, formikHelpers) => {
				formikHelpers.setSubmitting(true);
				try {
					await axios.post(`${url}/auth/get-admin-link`, {
						email: values.email,
					});
					onChange(values.email);
				} catch (err) {
					let message = 'Unknown error occurred';
					if (
						err instanceof AxiosError &&
						err.code === 'ERR_BAD_REQUEST'
					) {
						message =
							'You are not authorized to access this resource';
					} else if (err instanceof Error) {
						message = err.message;
					}
					formikHelpers.setErrors({
						general: message,
					});
				}
				formikHelpers.setSubmitting(false);
			}}
		>
			{({
				handleSubmit,
				handleChange,
				values,
				isSubmitting,
				touched,
				errors,
			}) => (
				<form onSubmit={handleSubmit} id="asaphSignIn">
					<VStack>
						<Heading size="h2">Log in/Sign up</Heading>
						<Input
							id="email"
							name="email"
							type="email"
							placeholder="Email"
							onChange={handleChange}
							value={values.email}
						/>
						<div className="vsb-8" />
						<Button type="submit" disabled={isSubmitting}>
							Get magic code
						</Button>
						<div className="vsb-20">
							{touched.email &&
								(errors.email || errors.general) && (
									<Text colorScheme="warning">
										{errors.email || errors.general}
									</Text>
								)}
						</div>
					</VStack>
				</form>
			)}
		</Formik>
	);
};

export default EmailForm;
