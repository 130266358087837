export const loginUrl = '/';
export const dashboardUrl = '/';
export const pipelineJobsUrl = '/jobs';
export const videosUrl = '/videos';
export const reelsUrl = '/reels';
export const videoCollectionsUrl = '/video-collections';
export const assetsUrl = '/assets';
export const contributorsUrl = '/contributors';
export const songsUrl = '/songs';
export const generateSetlist = '/ai/generate-setlist';
export const aiSettings = '/ai/explore';
export const songWeights = '/ai/song-weights';
export const songWeight = '/ai/song-weights/:id';
export const enrich = '/ai/enrich';
