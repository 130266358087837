import React from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import {
	ContributorInput,
	useCreateContributorMutation,
	useCreateVideoMutation,
	useUpdateContributorMutation,
	useUpdateVideoMutation,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { ContributorInputSchema } from '@/graphql/validation';
import {
	Button,
	ButtonGroup,
	Checkbox,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { omit } from 'ramda';

const inputSchema = ContributorInputSchema();

const initialValues: ContributorInput & { id: string } = {
	id: '',
	description: '',
	imageUrl: '',
	name: '',
	role: '',
};

export type CreateContributorInitialValues = typeof initialValues;

type CreateContributorModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
	initialData?: typeof initialValues;
};

const CreateContributorModal: React.FC<CreateContributorModalProps> = ({
	isOpen,
	onClose,
	onSuccess,
	initialData = initialValues,
}) => {
	const { mutateAsync, error: createError } =
		useCreateContributorMutation<FetchError>({
			onSuccess: onSuccess,
		});
	const { mutateAsync: updateMutateAsync, error: updateError } =
		useUpdateContributorMutation<FetchError>({
			onSuccess: onSuccess,
		});

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{initialData.id ? 'Update' : 'Create'} Contributor
				</ModalHeader>
				<ModalCloseButton />
				<Formik
					key="asaphSignUn"
					initialValues={initialData}
					validationSchema={inputSchema}
					onSubmit={async (values, formikHelpers) => {
						try {
							const input = omit(['id', 'contributor'], values);
							if (initialData.id) {
								await updateMutateAsync({
									input,
									contributorId: initialData.id,
								});
							} else {
								await mutateAsync({ input });
							}
							onClose();
						} catch (err) {
							console.error(err);
						} finally {
							formikHelpers.setSubmitting(false);
						}
					}}
				>
					{({
						handleSubmit,
						handleChange,
						values,
						isSubmitting,
						touched,
						errors,
					}) => (
						<form onSubmit={handleSubmit}>
							<ModalBody>
								<Stack spacing={4}>
									<FormikField
										type="text"
										name="name"
										handleChange={handleChange}
										value={values.name}
										isRequired
									/>
									<FormikField
										type="text"
										name="role"
										handleChange={handleChange}
										value={values.role}
										isRequired
									/>
									<FormikField
										type="text"
										name="imageUrl"
										handleChange={handleChange}
										value={values.imageUrl}
										isRequired
									/>
									<Textarea
										name="description"
										placeholder="description"
										onChange={handleChange}
										value={values.description || ''}
									/>
								</Stack>

								<ErrorInspector
									JSON={{
										form: errors,
										request: initialData.id
											? updateError &&
											  updateError.toJSON()
											: createError &&
											  createError.toJSON(),
									}}
								/>
							</ModalBody>
							<ModalFooter>
								<ButtonGroup w="100%" spacing={2}>
									<Button type="submit">
										{initialData.id ? 'Update' : 'Create'}
									</Button>
									<Button
										variant="ghost"
										mr={3}
										onClick={onClose}
									>
										Close
									</Button>
								</ButtonGroup>
							</ModalFooter>
						</form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default CreateContributorModal;
