import React from 'react';

import * as z from 'zod';

export * from './EnvironmentProvider';

export * from './context';

export enum Environments {
	LOCAL,
	STAGING,
	UAT,
	PRODUCTION,
}

export const zEnvironment = z.object({
	version: z.literal('1'),
	environment: z.nativeEnum(Environments),
	environmentString: z.string(),
	api: z.object({
		url: z.string(),
	}),
	mediaDomainRoot: z.string(),
	integrations: z.object({
		chargebee: z.object({
			domain: z.string(),
			planId: z.string(),
		}),
		analytics: z.object({
			measurementId: z.string(),
			enabled: z.boolean(),
		}),
		intercom: z.object({
			appId: z.string(),
		}),
		onesignal: z.object({
			appId: z.string(),
		}),
	}),
});

export type Environment = z.infer<typeof zEnvironment>;

export const ENV_DEFAULT_STRING = '__DEFAULT_VALUE__';

export const defaultEnvironment: Environment = {
	version: '1',
	environment: Environments.LOCAL,
	environmentString: ENV_DEFAULT_STRING,
	mediaDomainRoot: ENV_DEFAULT_STRING,
	api: {
		url: ENV_DEFAULT_STRING,
	},
	integrations: {
		chargebee: {
			domain: ENV_DEFAULT_STRING,
			planId: ENV_DEFAULT_STRING,
		},
		analytics: {
			enabled: false,
			measurementId: ENV_DEFAULT_STRING,
		},
		intercom: {
			appId: ENV_DEFAULT_STRING,
		},
		onesignal: {
			appId: ENV_DEFAULT_STRING,
		},
	},
};

export const EnvironmentContext =
	React.createContext<Environment>(defaultEnvironment);
