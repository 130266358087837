import React, { useContext } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import {
	ListVideoCollectionsQuery,
	useCreateVideoMutation,
	useListVideoCollectionsQuery,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { VideoInputSchema } from '@/graphql/validation';
import {
	Button,
	Checkbox,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Stack,
	Text,
	Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import ReactPlayer from 'react-player';

import { PipelineJobContext } from '../PipelineJobs';

type VideoPlayerModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({
	isOpen,
	onClose,
}) => {
	const { videoUrl } = useContext(PipelineJobContext);
	console.log(videoUrl);
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Preview Video</ModalHeader>
				<ModalCloseButton />

				<ModalBody>
					{!!videoUrl && (
						<ReactPlayer
							width="100%"
							height="600px"
							controls
							url={videoUrl}
							config={{
								file: {
									forceHLS: true,
								},
							}}
						/>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default VideoPlayerModal;
