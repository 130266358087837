import React, { useState } from 'react';

import FormikField from '@/components/shared/FormikField';
import {
	useDeleteEnrichTagMutation,
	useDeleteSongMutation,
	useListEnrichTagsQuery,
	useListSongsQuery,
	useMergeSongsMutation,
} from '@/graphql';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	Stack,
	StackDivider,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';

import CreateRecordModal, {
	CreateRecordInitialValues,
} from './components/CreateRecordModal';
import TestEnrichModal from './components/TestEnrichModal';
import AnalyzeLyricsModal from './components/TestLyricsModal';
import AnalyzePassageModal from './components/TestPassageModal';

const Enrich = () => {
	const { data, error, isLoading, refetch } = useListEnrichTagsQuery();
	const { mutateAsync } = useDeleteSongMutation({
		onSuccess: () => {
			refetch();
		},
	});
	const { mutateAsync: deleteMutation, isLoading: isMergeLoading } =
		useDeleteEnrichTagMutation({
			onSuccess: () => {
				refetch();
			},
		});

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isTestOpen,
		onOpen: onTestOpen,
		onClose: onTestClose,
	} = useDisclosure();
	const {
		isOpen: isAnalyzeLyricsOpen,
		onOpen: onAnalyzeLyricsOpen,
		onClose: onAnalyzeLyricsClose,
	} = useDisclosure();
	const {
		isOpen: isAnalyzeBiblePassageOpen,
		onOpen: onAnalyzeBiblePassageOpen,
		onClose: onAnalyzeBiblePassageClose,
	} = useDisclosure();
	const toast = useToast();
	const [initialData, setInitialData] = useState<CreateRecordInitialValues>();
	const [searchParam, setSearchParam] = useState('');
	const [withLyrics, setWithLyrics] = useState(false);
	const [onlySelected, setOnlySelected] = useState(false);
	const [viewAs, setViewAs] = useState<'grid' | 'rows'>('rows');
	const [selectedSongs, setSelectedSongs] = useState<string[]>([]);

	if (isLoading) {
		return <Spinner />;
	}

	if (error || !data) {
		throw error;
	}

	return (
		<>
			<CreateRecordModal
				isOpen={isOpen}
				onClose={onClose}
				onSuccess={refetch}
				initialData={initialData}
			/>
			<TestEnrichModal isOpen={isTestOpen} onClose={onTestClose} />
			<AnalyzeLyricsModal
				isOpen={isAnalyzeLyricsOpen}
				onClose={onAnalyzeLyricsClose}
			/>
			<AnalyzePassageModal
				isOpen={isAnalyzeBiblePassageOpen}
				onClose={onAnalyzeBiblePassageClose}
			/>

			<Flex justifyContent="center" gap="5px" pt="50px" mb="15px">
				<Button
					flexShrink={0}
					onClick={() => {
						setInitialData(undefined);
						onOpen();
					}}
				>
					Create enrich tag
				</Button>

				<Button flexShrink={0} onClick={onTestOpen}>
					Test enrich
				</Button>
			</Flex>

			<Flex justifyContent="center" gap="5px" pb="50px">
				<Button flexShrink={0} onClick={onAnalyzeLyricsOpen}>
					Analyze Lyrics
				</Button>
				<Button flexShrink={0} onClick={onAnalyzeBiblePassageOpen}>
					Analyze Passage
				</Button>
			</Flex>
			<Flex gap="20px" mt="30px" flexWrap="wrap">
				{data.listEnrichTags.map((record) => {
					if (!record) {
						return null;
					}

					return (
						<Card>
							<CardHeader>
								<Heading size="md">{record.tag}</Heading>
							</CardHeader>

							<CardBody>
								<Stack divider={<StackDivider />} spacing="4">
									<Box>
										<Heading
											size="xs"
											textTransform="uppercase"
										>
											Keywords
										</Heading>
										<Text pt="2" fontSize="sm" maxW={400}>
											{record.keywords.join(', ')}
										</Text>
									</Box>
									<Box>
										<Button
											onClick={() => {
												setInitialData({
													...record,
													tagColumn: record.tagColumn,
													keywords: JSON.stringify(
														record.keywords,
													),
												});
												onOpen();
											}}
										>
											Update
										</Button>
										<Popover>
											{({ onClose }) => (
												<>
													<PopoverTrigger>
														<Button
															variant="ghost"
															colorScheme="red"
														>
															Delete
														</Button>
													</PopoverTrigger>
													<PopoverContent>
														<PopoverArrow />
														<PopoverHeader>
															Confirmation!
														</PopoverHeader>
														<PopoverBody>
															<ButtonGroup
																spacing="2"
																w="100%"
															>
																<Button
																	variant="solid"
																	colorScheme="red"
																	onClick={async () => {
																		try {
																			await deleteMutation(
																				{
																					enrichTagId:
																						record.id,
																				},
																			);

																			toast(
																				{
																					title: `Tag ${record.tag} deleted!`,
																				},
																			);
																			onClose();
																		} catch (err) {
																			console.error(
																				err,
																			);
																			toast(
																				{
																					title: 'Error deleting song!',
																					status: 'error',
																					description:
																						err instanceof
																						Error
																							? err.message
																							: 'Unkown error, check console',
																				},
																			);
																		}
																	}}
																>
																	Yes
																</Button>
																<Button
																	variant="ghost"
																	onClick={
																		onClose
																	}
																>
																	No
																</Button>
															</ButtonGroup>
														</PopoverBody>
													</PopoverContent>
												</>
											)}
										</Popover>
									</Box>
								</Stack>
							</CardBody>
						</Card>
					);
				})}
			</Flex>
		</>
	);
};

export default Enrich;
