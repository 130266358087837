import React, {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from 'react';

import { useGetMyPermissionsQuery } from '@/graphql';
import { isNil } from 'ramda';

interface PermissionsContext {
	isLoading: boolean;
	permissions: string[];
	hasPermission: (action: string) => boolean;
}

const defaultValue: PermissionsContext = {
	isLoading: false,
	permissions: [],
	hasPermission: () => false,
};

const PermissionsContext = createContext<PermissionsContext>(defaultValue);

export function usePermissions(): PermissionsContext {
	const context = useContext(PermissionsContext);
	if (isNil(context)) {
		throw new Error(
			`usePermissions must be used within a PermissionsProvider`,
		);
	}

	return context;
}

export const PermissionsProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const { data, isLoading, error } = useGetMyPermissionsQuery();
	const permissions = useMemo(() => data?.getMyPermissions || [], [data]);
	const hasPermission = useCallback(
		(action: string) => {
			return permissions.includes(action);
		},
		[permissions],
	);

	if (error) {
		throw error;
	}

	return (
		<PermissionsContext.Provider
			value={{
				isLoading,
				permissions,
				hasPermission,
			}}
		>
			{children}
		</PermissionsContext.Provider>
	);
};

export default PermissionsProvider;
