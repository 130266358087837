import React from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import {
	ListVideoCollectionsQuery,
	useCreateVideoMutation,
	useListVideoCollectionsQuery,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { VideoInputSchema } from '@/graphql/validation';
import {
	Button,
	Checkbox,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Stack,
	Text,
	Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';

const videoInputSchema = VideoInputSchema();

type SelectVideoCollectionModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: (selectedVideoCollectionId: string) => Promise<void>;
	existingVideoCollectionIds: string[];
};

const SelectVideoCollectionModal: React.FC<SelectVideoCollectionModalProps> = ({
	isOpen,
	onClose,
	onSuccess,
	existingVideoCollectionIds,
}) => {
	const {
		data: videoCollectionsData,
		isLoading: videoCollectionsLoading,
		error: videoCollectionsError,
	} = useListVideoCollectionsQuery<ListVideoCollectionsQuery, FetchError>();

	if (videoCollectionsError) {
		return <ErrorInspector JSON={videoCollectionsError} />;
	}

	const videoCollections = videoCollectionsData?.listVideoCollections || [];

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Select Video Collection</ModalHeader>
				<ModalCloseButton />

				<ModalBody>
					{!!videoCollectionsLoading && <Spinner />}
					{videoCollections.map((vc) => (
						<Button
							isDisabled={existingVideoCollectionIds.includes(
								vc.id,
							)}
							size="md"
							display="block"
							my={2}
							onClick={() => onSuccess(vc.id)}
						>
							{vc.name}
						</Button>
					))}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default SelectVideoCollectionModal;
