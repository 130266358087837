import React, { PropsWithChildren, useState } from 'react';

import VideoCard from '@/components/shared/VideoCard';
import { songWeights } from '@/config/urls';
import {
	SongWeight,
	useDeleteSongWeightMutation,
	useDeleteVideoMutation,
	useListSongWeightsQuery,
	useListVideosQuery,
} from '@/graphql';
import { VideoInputSchema } from '@/graphql/validation';
import { useAuth } from '@/providers/AuthProvider';
import {
	Box,
	Button,
	ButtonGroup,
	Flex,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import { useNavigate } from 'react-router';
import Editor from 'react-simple-code-editor';

import CreateUpdateSongWeightModal, {
	CreateUpdateWeightValues,
} from './CreateUpdateSongWeightModal';

const SongWeights = () => {
	const navigate = useNavigate();
	const { data, error, isLoading, refetch } = useListSongWeightsQuery();
	const { mutateAsync } = useDeleteSongWeightMutation({
		onSuccess: () => {
			refetch();
		},
	});
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const [initialData, setInitialData] = useState<CreateUpdateWeightValues>();

	if (isLoading) {
		return <Spinner />;
	}

	if (error || !data) {
		throw error;
	}

	return (
		<>
			<CreateUpdateSongWeightModal
				isOpen={isOpen}
				onClose={onClose}
				onSuccess={refetch}
				initialData={initialData}
			/>
			<Flex mt="30px" flexWrap="wrap">
				{data.listSongWeights.map((songWeight) => {
					if (!songWeight) {
						return null;
					}
					return (
						<WeightCard
							songWeight={songWeight}
							key={songWeight.id}
							footer={
								<ButtonGroup spacing="2" w="100%">
									<Button
										variant="outline"
										onClick={() => {
											navigate(
												`${songWeights}/${songWeight.id}`,
											);
										}}
									>
										Configure
									</Button>
									<Popover>
										{({ onClose }) => (
											<>
												<PopoverTrigger>
													<Button
														variant="ghost"
														colorScheme="red"
													>
														Delete
													</Button>
												</PopoverTrigger>
												<PopoverContent>
													<PopoverArrow />
													<PopoverHeader>
														Confirmation!
													</PopoverHeader>
													<PopoverBody>
														<ButtonGroup
															spacing="2"
															w="100%"
														>
															<Button
																variant="solid"
																colorScheme="red"
																onClick={async () => {
																	try {
																		await mutateAsync(
																			{
																				weightId:
																					songWeight.id,
																			},
																		);

																		toast({
																			title: `Song Weight ${songWeight.name} deleted!`,
																		});
																		onClose();
																	} catch (err) {
																		console.error(
																			err,
																		);
																		toast({
																			title: 'Error deleting song weight!',
																			status: 'error',
																			description:
																				err instanceof
																				Error
																					? err.message
																					: 'Unkown error, check console',
																		});
																	}
																}}
															>
																Yes
															</Button>
															<Button
																variant="ghost"
																onClick={
																	onClose
																}
															>
																No
															</Button>
														</ButtonGroup>
													</PopoverBody>
												</PopoverContent>
											</>
										)}
									</Popover>
								</ButtonGroup>
							}
						/>
					);
				})}
			</Flex>

			<Button
				display="block"
				mx="auto"
				mt="150px"
				flexShrink={0}
				onClick={() => {
					setInitialData(undefined);
					onOpen();
				}}
			>
				Create Song Weight
			</Button>
		</>
	);
};

export default SongWeights;

const WeightCard: React.FC<{
	songWeight: SongWeight;
	footer: React.ReactNode;
}> = ({
	songWeight: {
		name,
		description,
		function: functionProp,
		lowValue,
		highValue,
		key,
	},
	footer,
}) => {
	return (
		<Flex flexBasis="33.33%" flexDirection="column">
			<Text mb={1} size="lg" fontWeight={600}>
				{name} ({lowValue}/{highValue})
			</Text>
			<Text size="md">{description}</Text>
			<Text mb={2}>
				Key: <strong>"{key}"</strong>
			</Text>
			<Box mb={5}>
				<Editor
					value={functionProp}
					onValueChange={() => {}}
					disabled
					highlight={(code) => highlight(code, languages.js, 'json')}
					style={{ fontSize: 10 }}
				/>
			</Box>
			<Box mt="auto">{footer}</Box>
		</Flex>
	);
};
