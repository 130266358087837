import { extendTheme, StyleFunctionProps } from '@chakra-ui/react';

import breakpoints from './breakpoints';
import colors from './colors';
import components from './components';
import sizes from './sizes';
import typography from './typography';

const themeSettings = {
	colors,
	...typography,
	sizes,
	components,
	breakpoints,
	styles: {
		global: (props: StyleFunctionProps) => ({
			body: {
				color: 'default',
				bg: '#FFFFFF',
			},
		}),
	},
};

const theme = extendTheme(themeSettings);

export default theme;
