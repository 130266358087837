import React from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import {
	useCreateVideoCollectionMutation,
	useUpdateVideoCollectionMutation,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { VideoCollectionInputSchema } from '@/graphql/validation';
import {
	Button,
	Checkbox,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { omit } from 'ramda';

const inputSchema = VideoCollectionInputSchema();

export type CreateVideoCollectionInitialData = {
	id: string;
	name: string;
	onboarding: boolean;
	videoIds: string[];
	paid: boolean;
	hex: string;
};

const initialValues: CreateVideoCollectionInitialData = {
	id: '',
	name: '',
	onboarding: false,
	videoIds: [],
	paid: false,
	hex: '',
};

type CreateVideoCollectionModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
	initialData?: CreateVideoCollectionInitialData;
};

const CreateVideoCollectionModal: React.FC<CreateVideoCollectionModalProps> = ({
	isOpen,
	onClose,
	onSuccess,
	initialData = initialValues,
}) => {
	const {
		mutateAsync,
		error: createVideoError,
		isLoading: createVideoLoading,
	} = useCreateVideoCollectionMutation<FetchError>({
		onSuccess: onSuccess,
	});
	const {
		mutateAsync: updateMutateAsync,
		error: updateVideoError,
		isLoading: updateVideoLoading,
	} = useUpdateVideoCollectionMutation<FetchError>({
		onSuccess: onSuccess,
	});

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{initialData.id ? 'Update' : 'Create'} Video Collection
				</ModalHeader>
				<ModalCloseButton />
				<Formik
					key="asaphSignUn"
					initialValues={initialData}
					validationSchema={inputSchema}
					onSubmit={async (values, formikHelpers) => {
						try {
							const input = omit(['id', 'videos'], values);
							if (initialData.id) {
								await updateMutateAsync({
									input,
									videoCollectionId: initialData.id,
								});
							} else {
								await mutateAsync({ input });
							}
							onClose();
						} catch (err) {
							console.error(err);
						} finally {
							formikHelpers.setSubmitting(false);
						}
					}}
				>
					{({
						handleSubmit,
						handleChange,
						values,
						isSubmitting,
						touched,
						errors,
					}) => (
						<form onSubmit={handleSubmit}>
							<ModalBody>
								<Stack spacing={4}>
									<FormikField
										type="text"
										name="name"
										handleChange={handleChange}
										value={values.name}
										isRequired
									/>
									<Checkbox
										name="onboarding"
										placeholder="onboarding"
										onChange={handleChange}
										isChecked={values.onboarding}
									>
										Onboarding
									</Checkbox>
									<Checkbox
										name="paid"
										placeholder="paid"
										onChange={handleChange}
										isChecked={values.paid}
									>
										Is Paid
									</Checkbox>
									<FormikField
										type="text"
										name="hex"
										helperText="In #FFFFFF format"
										handleChange={handleChange}
										value={values.hex}
										isRequired
									/>
								</Stack>

								<ErrorInspector
									JSON={{
										form: errors,
										request:
											createVideoError &&
											createVideoError.toJSON(),
									}}
								/>
							</ModalBody>
							<ModalFooter>
								<Button
									variant="ghost"
									mr={3}
									onClick={onClose}
								>
									Close
								</Button>
								<Button type="submit">
									{initialData.id ? 'Update' : 'Create'}
								</Button>
							</ModalFooter>
						</form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default CreateVideoCollectionModal;
