import React from 'react';

import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardFooter,
	CardProps,
	Divider,
	Image,
	Text,
} from '@chakra-ui/react';

type ContributorCardProps = CardProps & {
	name: string;
	imageUrl?: string | null;
	footer?: React.ReactNode;
};

export const contributorFieldKeys = ['role', 'id', 'name', 'imageUrl'];

const ContributorCard: React.FC<ContributorCardProps> = ({
	name,
	imageUrl,
	footer,
	...props
}) => {
	return (
		<Card {...props} display="inline-flex">
			<CardBody>
				{!!imageUrl && <Image src={imageUrl} />}
				<Text>{name}</Text>
			</CardBody>

			{footer && (
				<>
					<Divider borderColor="brand.200" />
					<CardFooter>{footer}</CardFooter>
				</>
			)}
		</Card>
	);
};

export default ContributorCard;
