import React from 'react';

import { Button, Flex, FlexProps, HStack, Input, Select } from '@chakra-ui/react';
import { Formik } from 'formik';

type FilterKeys = {
  sortBy: string;
  sortDirection: string;
  srcVideo: string;
}

type FiltersProps = {
  values: FilterKeys
	onChange: (state: FilterKeys) => void;
};

const Filters: React.FC<Omit<FlexProps, 'onChange'> & FiltersProps> = ({ values, onChange, ...flexProps }) => {
	return (
		<Formik
			key="asaphSignUn"
			initialValues={{
				sortBy: values.sortBy || 'started',
				sortDirection: values.sortDirection || 'asc',
				srcVideo: values.srcVideo || '',
			}}
			onSubmit={async (values, formikHelpers) => {
				onChange(values);
			}}
		>
			{({ handleChange, handleSubmit, values }) => (
				<form
					onSubmit={handleSubmit}
				>
          <Flex {...flexProps}>
            <Select
              id="sortBy"
              onChange={handleChange}
              value={values.sortBy}
              placeholder="Sort by"
            >
              <option value="started">Started at</option>
            </Select>
            <Select
              id="sortDirection"
              onChange={handleChange}
              value={values.sortDirection}
              placeholder="Sort direction"
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Select>
            <Input
              type="text"
              id="srcVideo"
              value={values.srcVideo}
              onChange={handleChange}
              placeholder="Search by video source file name"
            />
            <Button type="submit">Submit</Button>
          </Flex>
				</form>
			)}
		</Formik>
	);
};

export default Filters;
