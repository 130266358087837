import React, { useMemo } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import {
	ListContributorsQuery,
	SongWeight,
	SongWeightInput,
	useCreateSongWeightMutation,
	useCreateVideoMutation,
	useListContributorsQuery,
	useUpdateSongWeightMutation,
	useUpdateVideoMutation,
	VideoInput,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { SongWeightInputSchema, VideoInputSchema } from '@/graphql/validation';
import {
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { omit } from 'ramda';

const songWeightInputSchema = SongWeightInputSchema();
const initialValues: SongWeight = {
	id: '',
  name: '',
	description: '',
  function: '',
  highValue: 0,
  lowValue: 0,
  key: '',
};

export type CreateUpdateWeightValues = typeof initialValues;

type CreateUpdateWeightModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
	initialData?: CreateUpdateWeightValues;
};

const CreateVideoModal: React.FC<CreateUpdateWeightModalProps> = ({
	isOpen,
	onClose,
	onSuccess,
	initialData = initialValues,
}) => {
	const {
		mutateAsync,
		error: createVideoError,
		isLoading: createVideoLoading,
	} = useCreateSongWeightMutation<FetchError>({
		onSuccess: onSuccess,
	});
	const {
		mutateAsync: updateMutateAsync,
		error: updateVideoError,
		isLoading: updateVideoLoading,
	} = useUpdateSongWeightMutation<FetchError>({
		onSuccess: onSuccess,
	});

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{initialData.id ? 'Update' : 'Create'} Song Weight
				</ModalHeader>
				<ModalCloseButton />
				<Formik
					key="asaphSignUn"
					initialValues={initialData}
					validationSchema={songWeightInputSchema}
					onSubmit={async (values, formikHelpers) => {
						try {
							const input = {
								...omit(['id', 'contributor'], values),
								lowValue: parseInt(values.lowValue.toString()),
								highValue: parseInt(values.highValue.toString()),
							};
							if (initialData.id) {
								await updateMutateAsync({
									input,
									weightId: initialData.id,
								});
							} else {
								await mutateAsync({ input });
							}
							onClose();
						} catch (err) {
							console.error(err);
						} finally {
							formikHelpers.setSubmitting(false);
						}
					}}
				>
					{({
						handleSubmit,
						handleChange,
						values,
						isSubmitting,
						touched,
						errors,
					}) => (
						<form onSubmit={handleSubmit}>
							<ModalBody>
								<Stack spacing={4}>
									<FormikField
										type="text"
										name="name"
										handleChange={handleChange}
										value={values.name}
										isRequired
									/>

									<FormikField
										type="text"
										name="description"
										handleChange={handleChange}
										value={values.description}
										isRequired
									/>
									<FormikField
										type="text"
										name="function"
										handleChange={handleChange}
										value={values.function}
										isRequired
									/>
									<FormikField
										type="text"
										name="key"
										handleChange={handleChange}
										value={values.key}
										isRequired
									/>

									<FormikField
										type="number"
										label="Low Value (in seconds)"
										name="lowValue"
										handleChange={handleChange}
										value={values.lowValue}
										isRequired
									/>

									<FormikField
										type="number"
										label="High Value (in seconds)"
										name="highValue"
										handleChange={handleChange}
										value={values.highValue}
										isRequired
									/>

								</Stack>

								<ErrorInspector
									JSON={{
										form: errors,
										request: initialData.id
											? updateVideoError &&
											  updateVideoError.toJSON()
											: createVideoError &&
											  createVideoError.toJSON(),
									}}
								/>
							</ModalBody>
							<ModalFooter>
								<ButtonGroup w="100%" spacing={2}>
									<Button type="submit">
										{initialData.id ? 'Update' : 'Create'}
									</Button>
									<Button
										variant="ghost"
										mr={3}
										onClick={onClose}
									>
										Close
									</Button>
								</ButtonGroup>
							</ModalFooter>
						</form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default CreateVideoModal;
