import React, {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';

import { Spinner } from '@chakra-ui/react';

export const LoadingContext = createContext<{
	isLoading: boolean;
	finishedLoading: () => void;
	setIsLoading: (isLoading: boolean) => void;
}>({
	isLoading: true,
	finishedLoading: () => {},
	setIsLoading: () => {},
});

export const useAppLoading = () => useContext(LoadingContext);

const AppLoading: React.FC<PropsWithChildren> = ({ children }) => {
	const [isVisible, setIsVisible] = useState(false);
	const finishedLoading = useCallback(() => {
		setTimeout(() => setIsVisible(false), 0);
	}, [setIsVisible]);
	return (
		<LoadingContext.Provider
			value={{
				finishedLoading,
				isLoading: isVisible,
				setIsLoading: (isLoading) => setIsVisible(isLoading),
			}}
		>
			{isVisible && <Spinner />}
			{children}
		</LoadingContext.Provider>
	);
};

export const FinishedLoading: React.FC<PropsWithChildren> = ({ children }) => {
	const { isLoading, finishedLoading } = useAppLoading();
	useEffect(() => {
		if (isLoading) {
			finishedLoading();
		}
	}, [isLoading, finishedLoading]);
	return <>{children}</>;
};

export default AppLoading;
