import React, { PropsWithChildren } from 'react';

import { Box } from '@chakra-ui/react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { useAppLoading } from '../AppLoading';

const ErrorScreen = () => {
	const { finishedLoading } = useAppLoading();
	finishedLoading();
	return (
		<Box>
			There was an unexpected error. Please contact support@asaph.io for
			help if the problem persists.
		</Box>
	);
};

export const ErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<ReactErrorBoundary FallbackComponent={ErrorScreen}>
			{children}
		</ReactErrorBoundary>
	);
};
