import React, { PropsWithChildren } from 'react';

import { Environment, EnvironmentContext } from '.';
import { getEnvironment } from './config';

const PACKAGE = 'EnvironmentProvider';

type EnvironmentProviderProps = {
	environment?: Environment;
};

export const EnvironmentProvider: React.FC<
	PropsWithChildren<EnvironmentProviderProps>
> = ({ environment, children }) => {
	const selectedEnvironment =
		environment === undefined ? getEnvironment() : environment;

	console.log(`[${PACKAGE}] Returning `, {
		environment,
		selectedEnvironment,
	});

	return (
		<EnvironmentContext.Provider value={selectedEnvironment}>
			{children}
		</EnvironmentContext.Provider>
	);
};

export default EnvironmentProvider;
