import React, { useMemo } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import {
	ListContributorsQuery,
	useCreateVideoMutation,
	useListContributorsQuery,
	useUpdateVideoMutation,
	ReelInput,
  useCreateReelMutation,
  useUpdateReelMutation,
  useListVideosQuery,
  ListVideosQuery,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { ReelInputSchema } from '@/graphql/validation';
import {
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { omit } from 'ramda';

const inputSchema = ReelInputSchema();
const initialValues: ReelInput & {
	id?: string;
} = {
	id: '',
  name: '',
	posterUrl: '',
	url: '',
  videoId: '',
};

export type CreateInitialValues = typeof initialValues;

type CreateModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
	initialData?: typeof initialValues;
};

const CreateModal: React.FC<CreateModalProps> = ({
	isOpen,
	onClose,
	onSuccess,
	initialData = initialValues,
}) => {
	const {
		mutateAsync,
		error: createError,
		isLoading: createLoading,
	} = useCreateReelMutation<FetchError>({
		onSuccess: onSuccess,
	});
	const {
		mutateAsync: updateMutateAsync,
		error: updateError,
		isLoading: updateLoading,
	} = useUpdateReelMutation<FetchError>({
		onSuccess: onSuccess,
	});
	const {
		data: videosData,
		error: videosError,
		isLoading: videosLoading,
	} = useListVideosQuery<ListVideosQuery, FetchError>();

	const videos = useMemo(
		() =>
			(videosData?.listVideos.filter(
				(c) => !!c,
			) as NonNullable<ListVideosQuery['listVideos'][0]>[]) ||
			[],
		[videosData],
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{initialData.id ? 'Update' : 'Create'} Video
				</ModalHeader>
				<ModalCloseButton />
				<Formik
					key="asaphSignUn"
					initialValues={initialData}
					validationSchema={inputSchema}
					onSubmit={async (values, formikHelpers) => {
						try {
							const input = {
								...omit(['id'], values),
							};
							if (initialData.id) {
								await updateMutateAsync({
									input,
									reelId: initialData.id,
								});
							} else {
								await mutateAsync({ input });
							}
							onClose();
						} catch (err) {
							console.error(err);
						} finally {
							formikHelpers.setSubmitting(false);
						}
					}}
				>
					{({
						handleSubmit,
						handleChange,
						values,
						isSubmitting,
						touched,
						errors,
					}) => (
						<form onSubmit={handleSubmit}>
							<ModalBody>
								<Stack spacing={4}>
									<FormikField
										type="text"
										name="name"
										handleChange={handleChange}
										value={values.name}
										isRequired
									/>

									<FormControl>
										<FormLabel>Video</FormLabel>

										{!!videosError && (
											<ErrorInspector
												JSON={videosError}
											/>
										)}
										{!!videos.length && (
											<Select
												value={
													values.videoId ||
													undefined
												}
												name="videoId"
												onChange={handleChange}
												placeholder="Select Video"
											>
												{videos.map(
													(video) => (
														<option
															selected={
																initialData.videoId ===
																video.id
															}
															value={
																video.id
															}
														>
															{`${video.name}`}
														</option>
													),
												)}
											</Select>
										)}
									</FormControl>
									<FormikField
										type="text"
										name="url"
                    helperText="Format: '/b64d48a9-dfa7-493d-8eda-879d417f7450/hls/reels_test1.m3u8'"
										handleChange={handleChange}
										value={values.url}
										isRequired
									/>
									<FormikField
										type="text"
										name="posterUrl"
                    helperText="Format: 'https://asaph-assets203124-develop.s3.ap-southeast-2.amazonaws.com/public/1---VIDEO.jpg'"
										handleChange={handleChange}
										value={values.posterUrl}
										isRequired
									/>
								</Stack>

								<ErrorInspector
									JSON={{
										form: errors,
										request: initialData.id
											? updateError &&
											  updateError.toJSON()
											: createError &&
											  createError.toJSON(),
									}}
								/>
							</ModalBody>
							<ModalFooter>
								<ButtonGroup w="100%" spacing={2}>
									<Button type="submit">
										{initialData.id ? 'Update' : 'Create'}
									</Button>
									<Button
										variant="ghost"
										mr={3}
										onClick={onClose}
									>
										Close
									</Button>
								</ButtonGroup>
							</ModalFooter>
						</form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default CreateModal;
