import React, { PropsWithChildren, useState } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import { useAnalyzeLyricsMutation, useTestEnrichMutation } from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import {
	Button,
	ButtonGroup,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import * as yup from 'yup';

const enrichTagInputSchema = yup.object({
	text: yup.string().defined(),
});

const initialValues: {
	text: string;
} = {
	text: '',
};

export type CreateRecordInitialValues = typeof initialValues;

type AnalyzeLyricsModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

const AnalyzeLyricsModal: React.FC<AnalyzeLyricsModalProps> = ({
	isOpen,
	onClose,
}) => {
	const { mutateAsync, data, isLoading } =
		useAnalyzeLyricsMutation<FetchError>();
	const [result, setResult] = useState<string>('');

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Analyze Lyrics</ModalHeader>
				<ModalCloseButton />
				<Formik
					initialValues={initialValues}
					validationSchema={enrichTagInputSchema}
					onSubmit={async (values, formikHelpers) => {
						try {
							const res = await mutateAsync({
								text: values.text,
							});

							setResult(
								JSON.parse(res.analyzeLyrics || '{}').content[0]
									.text,
							);
						} catch (err) {
							console.error(err);
						}
					}}
				>
					{({
						submitForm,
						handleSubmit,
						handleChange,
						values,
						errors,
						setFieldValue,
					}) => {
						return (
							<>
								<ModalBody>
									<FormControl>
										<Stack spacing={4}>
											<FormControl isRequired>
												<FormLabel>Text</FormLabel>
												<Textarea
													placeholder="text"
													name="text"
													onChange={handleChange}
													value={values.text}
												/>
											</FormControl>
										</Stack>
									</FormControl>

									<ErrorInspector
										JSON={{
											form: errors,
											request: data,
										}}
									/>

									<pre>{result}</pre>
								</ModalBody>
								<ModalFooter>
									<ButtonGroup w="100%" spacing={2}>
										<Button
											onClick={submitForm}
											type="submit"
											isLoading={isLoading}
										>
											Test
										</Button>
									</ButtonGroup>
								</ModalFooter>
							</>
						);
					}}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default AnalyzeLyricsModal;
