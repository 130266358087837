import { mergeDeepWith } from 'ramda';

import { Environment, Environments } from '.';

export const getRequiredStringEnvVar = (varName: string): string => {
	const value = getStringEnvVar(varName);
	if (!value) throw new Error(`Missing string value from: ${varName}`);
	return value;
};

function getStringEnvVar(varName: string, fallback = ''): string {
	const value = process.env[varName];
	// If the string has length, return the string else return undefined
	return value == undefined
		? fallback
		: value.length === 0
		? fallback
		: value;
}

function getEnvironmentValueStrict(): Environments {
	const envFlag = getRequiredStringEnvVar('REACT_APP_ENVIRONMENT');
	if (!Object.values(Environments).includes(envFlag)) {
		throw new Error(
			`Environment Variable REACT_APP_ENVIRONMENT needs to be one of ${Object.values(
				Environments,
			).join(', ')}`,
		);
	}

	switch (true) {
		case envFlag === 'PRODUCTION':
			return Environments.PRODUCTION;
		case envFlag === 'STAGING':
			return Environments.STAGING;
		case envFlag === 'UAT':
			return Environments.UAT;
		case envFlag === 'LOCAL':
		default:
			return Environments.LOCAL;
	}
}

const pickTruthy = (l, r) => (!!r ? r : l);

export const getEnvironment = (): Environment => {
	const env = getEnvironmentValueStrict();

	const defaults: Pick<Environment, 'version'> = {
		version: '1',
	};

	const overrides: Partial<Environment> = {
		api: {
			url: getStringEnvVar('REACT_APP_API_URL', undefined),
		},
	};

	console.log(overrides);

	const environmentConfigMap: Record<Environments, Environment> = {
		[Environments.LOCAL]: mergeDeepWith(
			pickTruthy,
			{
				...defaults,
				environment: Environments.LOCAL,
				environmentString: '[LOCAL]',
				// mediaDomainRoot:
				// 	'https://asaph-vod-pipeline-destination-r8cygx29axek.s3.ap-southeast-2.amazonaws.com/',
				mediaDomainRoot: 'https://media.app.asaph.io/',
				api: {
					url: 'http://localhost:4000',
				},
				integrations: {
					chargebee: {
						domain: 'asaph-test',
						planId: 'Live-Test',
					},
					analytics: {
						enabled: true,
						measurementId: 'G-QCYNMQ1YQ3',
					},
					intercom: {
						appId: 'wmwldqhe',
					},
					onesignal: {
						appId: '51bd47dc-739d-414b-ac29-e35bbd0c8fb0',
					},
				},
			},
			overrides,
		),
		[Environments.STAGING]: mergeDeepWith(
			pickTruthy,
			{
				...defaults,
				environment: Environments.STAGING,
				environmentString: '[STAGING]',
				mediaDomainRoot: 'https://media.app.asaph.io/',
				api: {
					url: 'https://asaph-server.herokuapp.com',
				},
				integrations: {
					chargebee: {
						domain: 'asaph-test',
						planId: 'Live-Test',
					},
					analytics: {
						enabled: true,
						measurementId: 'G-FLNJWMKWY6',
					},
					intercom: {
						appId: 'wmwldqhe',
					},
					onesignal: {
						appId: '51bd47dc-739d-414b-ac29-e35bbd0c8fb0',
					},
				},
			},
			overrides,
		),
		[Environments.UAT]: mergeDeepWith(
			pickTruthy,
			{
				...defaults,
				environment: Environments.UAT,
				environmentString: '[UAT]',
				mediaDomainRoot: 'https://media.app.asaph.io/',
				api: {
					url: 'http://localhost:4000',
				},
				integrations: {
					chargebee: {
						domain: 'asaph',
						planId: 'team-beta',
					},
					analytics: {
						enabled: true,
						measurementId: 'G-KDV176TBP5',
					},
					intercom: {
						appId: 'zoq6snc5',
					},
					onesignal: {
						appId: '51bd47dc-739d-414b-ac29-e35bbd0c8fb0',
					},
				},
			},
			overrides,
		),
		[Environments.PRODUCTION]: mergeDeepWith(
			pickTruthy,
			{
				...defaults,
				environment: Environments.PRODUCTION,
				environmentString: '[PRODUCTION]',
				mediaDomainRoot: 'https://media.app.asaph.io/',
				api: {
					url: 'http://localhost:4000',
				},
				integrations: {
					chargebee: {
						domain: 'asaph-test',
						planId: 'Live-Test',
					},
					analytics: {
						enabled: false,
						measurementId: 'G-DQ8RCDSQ0Q',
					},
					intercom: {
						appId: 'zoq6snc5',
					},
					onesignal: {
						appId: '51bd47dc-739d-414b-ac29-e35bbd0c8fb0',
					},
				},
			},
			overrides,
		),
	};
	return environmentConfigMap[env];
};
