import React, { useMemo, useState } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import VideoCard from '@/components/shared/VideoCard';
import {
	ListVideosQuery,
	useCreateVideoCollectionMutation,
	useListVideosQuery,
	useUpdateVideoCollectionMutation,
	VideoCollectionInput,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { VideoCollectionInputSchema } from '@/graphql/validation';
import {
	Button,
	ButtonGroup,
	Checkbox,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Stack,
	Text,
	Textarea,
	useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik';

type SelectVideoModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onChange: (videoId: string) => void;
	filterVideoIds?: string[];
};

const SelectVideoModal: React.FC<SelectVideoModalProps> = ({
	isOpen,
	onClose,
	onChange,
	filterVideoIds = [],
}) => {
	const { data, error, isLoading } = useListVideosQuery<
		ListVideosQuery,
		FetchError
	>();
	const [selectedVideoId, setSelectedVideoId] = useState<string>('');
	const toast = useToast();

	const videos = useMemo(
		() =>
			data?.listVideos.filter(
				(v) => !!v && !filterVideoIds.includes(v.id),
			) || [],
		[data, filterVideoIds],
	);

	return (
		<Modal isOpen={isOpen} size="full" onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add Video To Collection</ModalHeader>
				<ModalCloseButton />

				<ModalBody>
					{!!error && <ErrorInspector JSON={error} />}
					{isLoading ? (
						<Spinner />
					) : (
						<Flex flexWrap="wrap" gap="15px">
							{!videos.length && (
								<Text>
									All available videos have been added across
									all collections
								</Text>
							)}
							{videos.map(
								(video) =>
									!!video && (
										<VideoCard
											key={video.id}
											border={
												selectedVideoId === video.id
													? '1px solid blue'
													: ''
											}
											onClick={() =>
												setSelectedVideoId(video.id)
											}
											w="200px"
											{...video}
										/>
									),
							)}
						</Flex>
					)}
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<Button variant="ghost" mr={3} onClick={onClose}>
							Close
						</Button>
						<Button
							onClick={async () => {
								if (!selectedVideoId) {
									return toast({
										title: 'No video selected',
										status: 'warning',
									});
								}
								onChange(selectedVideoId);
								onClose();
							}}
						>
							Apply
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default SelectVideoModal;
