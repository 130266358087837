import React, { useState } from 'react';

import VideoCard from '@/components/shared/VideoCard';
import { useDeleteReelMutation, useListReelsQuery } from '@/graphql';
import {
	Button,
	ButtonGroup,
	Flex,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';

import CreateModal, {
	CreateInitialValues,
} from './components/CreateModal';

const Reels = () => {
	const { data, error, isLoading, refetch } = useListReelsQuery();
	const { mutateAsync } = useDeleteReelMutation({
		onSuccess: () => {
			refetch();
		},
	});
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const [initialData, setInitialData] = useState<CreateInitialValues>();

	if (isLoading) {
		return <Spinner />;
	}

	if (error || !data) {
		throw error;
	}

	return (
		<>
			<CreateModal
				isOpen={isOpen}
				onClose={onClose}
				onSuccess={refetch}
				initialData={initialData}
			/>
			<Flex gap="20px" mt="30px" alignItems="center" flexWrap="wrap">
				{data.listReels.map((reel) => {
					if (!reel) {
						return null;
					}
					return (
						<VideoCard
							flexShrink={0}
							key={reel.id}
							w="300px"
							{...reel}
							footer={
								<ButtonGroup spacing="2" w="100%">
									<Button
										variant="outline"
										onClick={() => {
											setInitialData({
												...reel,
											});
											onOpen();
										}}
									>
										Edit
									</Button>
									<Popover>
										{({ onClose }) => (
											<>
												<PopoverTrigger>
													<Button
														variant="ghost"
														colorScheme="red"
													>
														Delete
													</Button>
												</PopoverTrigger>
												<PopoverContent>
													<PopoverArrow />
													<PopoverHeader>
														Confirmation!
													</PopoverHeader>
													<PopoverBody>
														<ButtonGroup
															spacing="2"
															w="100%"
														>
															<Button
																variant="solid"
																colorScheme="red"
																onClick={async () => {
																	try {
																		await mutateAsync(
																			{
																				reelId:
																					reel.id,
																			},
																		);

																		toast({
																			title: `Reel ${reel.name} deleted!`,
																		});
																		onClose();
																	} catch (err) {
																		console.error(
																			err,
																		);
																		toast({
																			title: 'Error deleting reel!',
																			status: 'error',
																			description:
																				err instanceof
																				Error
																					? err.message
																					: 'Unkown error, check console',
																		});
																	}
																}}
															>
																Yes
															</Button>
															<Button
																variant="ghost"
																onClick={
																	onClose
																}
															>
																No
															</Button>
														</ButtonGroup>
													</PopoverBody>
												</PopoverContent>
											</>
										)}
									</Popover>
								</ButtonGroup>
							}
						/>
					);
				})}
			</Flex>

			<Button
				display="block"
				mx="auto"
				mt="150px"
				flexShrink={0}
				onClick={() => {
					setInitialData(undefined);
					onOpen();
				}}
			>
				Create reel
			</Button>
		</>
	);
};

export default Reels;
