import React, { PropsWithChildren } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import {
	EnrichTagInput,
	SongInput,
	useCreateEnrichTagMutation,
	useCreateSongMutation,
	useUpdateEnrichTagMutation,
	useUpdateSongMutation,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { EnrichTagInputSchema } from '@/graphql/validation';
import {
	Button,
	ButtonGroup,
	FormControl,
	FormHelperText,
	FormLabel,
	HStack,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Tag,
	Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { omit } from 'ramda';
import * as yup from 'yup';

const enrichTagInputSchema = yup.object({
	keywords: yup.string().defined(),
	tag: yup.string().defined(),
	tagColumn: yup.string().defined(),
});

const FormSectionTitle: React.FC<PropsWithChildren> = ({ children }) => (
	<FormLabel mt={10} fontWeight={800} fontSize={18}>
		{children}
	</FormLabel>
);

const initialValues: {
	id?: string;
	tag: string;
	keywords: string;
	tagColumn: string;
} = {
	keywords: '[]',
	tag: '',
	tagColumn: '',
};

export type CreateRecordInitialValues = typeof initialValues;

type CreateRecordModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
	initialData?: typeof initialValues;
};

const CreateRecordModal: React.FC<CreateRecordModalProps> = ({
	isOpen,
	onClose,
	onSuccess,
	initialData = initialValues,
}) => {
	const { mutateAsync, error: createRecordError } =
		useCreateEnrichTagMutation<FetchError>({
			onSuccess: onSuccess,
		});
	const { mutateAsync: updateMutateAsync, error: updateRecordError } =
		useUpdateEnrichTagMutation<FetchError>({
			onSuccess: onSuccess,
		});

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{initialData.id ? 'Update' : 'Create'} Enrich Tag
				</ModalHeader>
				<ModalCloseButton />
				<Formik
					key="asaphSignUn"
					initialValues={initialData}
					validationSchema={enrichTagInputSchema}
					onSubmit={async (values, formikHelpers) => {
						try {
							const input = omit(['id'], values);
							if (initialData.id) {
								await updateMutateAsync({
									input: {
										tag: values.tag,
										tagColumn: values.tagColumn,
										keywords: eval(
											values.keywords,
										) as unknown as string[],
									},
									enrichTagId: initialData.id,
								});
							} else {
								await mutateAsync({
									input: {
										tag: values.tag,
										tagColumn: values.tagColumn,
										keywords: eval(
											values.keywords,
										) as unknown as string[],
									},
								});
							}
							onClose();
						} catch (err) {
							console.error(err);
						} finally {
							formikHelpers.setSubmitting(false);
						}
					}}
				>
					{({
						handleSubmit,
						handleChange,
						values,
						errors,
						setFieldValue,
					}) => {
						return (
							<form onSubmit={handleSubmit}>
								<ModalBody>
									<FormControl>
										<Stack spacing={4}>
											<FormSectionTitle>
												Enrich tag details
											</FormSectionTitle>

											<FormikField
												type="text"
												name="tag"
												label="Tag"
												handleChange={handleChange}
												value={values.tag}
												isRequired
											/>

											<FormLabel>Tag Column</FormLabel>
											<Select
												value={values.tagColumn}
												name="tagColumn"
												onChange={handleChange}
												placeholder="Select tag column"
												isRequired
											>
												{[
													'gospelTags',
													'emotionTags',
													'energyTags',
													'songTypeTags',
													'themeTags',
												].map((key) => (
													<option
														selected={
															initialData.tagColumn ===
															key
														}
														value={key}
													>
														{key}
													</option>
												))}
											</Select>
											<FormikField
												type="text"
												name="keywords"
												label="keywords"
												handleChange={handleChange}
												value={values.keywords.toString()}
												isRequired
											/>
										</Stack>
									</FormControl>

									<ErrorInspector
										JSON={{
											form: errors,
											request: initialData.id
												? updateRecordError &&
												  updateRecordError.toJSON()
												: createRecordError &&
												  createRecordError.toJSON(),
										}}
									/>
								</ModalBody>
								<ModalFooter>
									<ButtonGroup w="100%" spacing={2}>
										<Button type="submit">
											{initialData.id
												? 'Update'
												: 'Create'}
										</Button>
										<Button
											variant="ghost"
											mr={3}
											onClick={onClose}
										>
											Close
										</Button>
									</ButtonGroup>
								</ModalFooter>
							</form>
						);
					}}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default CreateRecordModal;
