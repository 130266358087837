import React, { useState } from 'react';

import { useAppLoading } from '@/components/AppLoading';
import { PublicLayout } from '@/components/PublicLayout';
import * as Yup from 'yup';

import EmailForm from './components/EmailForm';
import VerifyForm from './components/VerifyForm';

const confirmSignUpValidationSchema = Yup.object().shape({
	code: Yup.string().required('Verification code is required'),
});

const LoginPage = () => {
	const [email, setEmail] = useState<string | null>(null);
	const { finishedLoading } = useAppLoading();
	finishedLoading();

	return (
		<PublicLayout>
			{!email && <EmailForm onChange={setEmail} />}
			{!!email && <VerifyForm email={email} onChange={setEmail} />}
		</PublicLayout>
	);
};

export default LoginPage;
